import React, { useEffect, useState } from 'react';
import { Navbar, Nav, Container, Button, NavDropdown, OverlayTrigger, Popover } from 'react-bootstrap';
import NewLog from "../../../Assets/dalmaf-vertical-logo.png";
import '../../../index.css';
import '../Services/BestServices.css'; // Import CSS file for styling
import { NavLink, useNavigate } from 'react-router-dom';
import { GetLevel1, GetLevel2, GetMain } from '../../../Api/Api';
import UserBasic from '../UserBasic/UserBasic';
import styles from './UserNavbar.module.css';
// Login
import { useDispatch, useSelector } from 'react-redux';
import CustomModal from '../../CustomModal';
import {
    EmailValidation,
    NonEmptyKeyValidation,
    NonEmptyValidation,
    PasswordValidation,
    PhoneNumberValidation,
    RePasswordValidation,
    StringValidation,
} from "../../../Store/validate";
import { toast } from 'react-toastify';
import { ChangePassword, CreateNewsLetter, ForgotOtp, ForgotPassword, GetUser, SignUp, UserLogin, VerifyOtp } from '../../../Api/Api';
import { loginSuccess, registerDetails } from '../../../Store/auth/action';
import OtpInput from 'react-otp-input';

const Navigation = ({ megaMenuInput, mainMenuIdInput, mainTitleInput }) => {
    const navigate = useNavigate();
    const [mainTitle, setMainTitle] = useState("");
    const [mainId, setMainId] = useState("");
    const [mainHeader, setMainHeader] = useState([]);
    const [level1Title, setLevel1Title] = useState([]);
    const [level1Menu, setLevel1Menu] = useState([]);
    const [level2Menu, setLevel2Menu] = useState([]);
    const [megaMenuOpen, setMegaMenuOpen] = useState(megaMenuInput ? megaMenuInput : false);
    const [activeLink, setActiveLink] = useState(null);
    const [loginModalInput, setLoginModalInput] = useState(false);
    const [signupModalInput, setSignupModalInput] = useState(false);
    const [hoveredIndex, setHoveredIndex] = useState(null);

    const [isMobile, setIsMobile] = useState(false);
    const [isShowLevel1, setIsShowLevel1] = useState(true);
    const [isShowLevel2, setIsShowLevel2] = useState(true);
    const handleResize = () => {
        setIsMobile(window.innerWidth <= 768); // Set threshold for mobile view
    };

    useEffect(() => {
        setMegaMenuOpen(megaMenuInput);
        setMainTitle(mainTitleInput);
        if (mainMenuIdInput) {
            fetchMenuL1(mainMenuIdInput);
        }
    }, [megaMenuInput, mainMenuIdInput, megaMenuInput]);

    useEffect(() => {
        // Add or remove the no-scroll class on the body element
        if (megaMenuOpen) {
            document.body.classList.add('no-scroll');
        } else {
            document.body.classList.remove('no-scroll');
        }
        // Cleanup function to ensure the class is removed when the component unmounts
        return () => {
            document.body.classList.remove('no-scroll');
        };
    }, [megaMenuOpen]);

    useEffect(() => {
        handleResize(); // Initial check
        fetchMainHeader();
        setIsShowLevel1(true)
        setIsShowLevel2(false)
    }, []);


    // Login
    const [loginModal, setloginModal] = useState(loginModalInput);
    const [createModal, setcreateModal] = useState(signupModalInput);
    const [fpwModal, setfpwModal] = useState(false);
    const [otpModal, setotpModal] = useState(false);
    const [otpFgtModal, setotpFgtModal] = useState(false);
    const [changepswModal, setchangepswModal] = useState(false);
    const [code, setOtp] = useState("");
    const [validation, setValidation] = useState({});
    const [minutes, setMinutes] = useState(0);
    const [seconds, setSeconds] = useState(0);

    const [password, setPassword] = useState();
    const [registerDetail3, setRegisterDetails3] = useState({});

    const [registerValidation3, setRegisterValidation3] = useState({});
    const [registerValidation1, setRegisterValidation1] = useState({});
    const [registerDetail1, setRegisterDetails1] = useState({});


    const [userProfile, setUserProfile] = useState([]);
    const [registerValidation2, setRegisterValidation2] = useState({});
    const [registerDetail2, setRegisterDetails2] = useState({});

    const fetchMainHeader = async () => {
        let data = await GetMain("active");
        if (data && data.statusCode === 200) {
            const numAscending = data.data;
            setMainHeader(numAscending);
        } else {
            setMainHeader([]);
        }
    };

    const fetchMenuL1 = async (id) => {
        let data = await GetLevel1("active", id);
        if (data && data.statusCode === 200) {
            const numAscending = data.data;
            setLevel1Menu(numAscending);
            fetchMenuL2("active", id, numAscending?.[0]?._id);
            setLevel1Title(numAscending?.[0]?.title);
        } else {
            setLevel1Menu([]);
        }
    };

    const fetchMenuL2 = async (status, id, id1) => {
        let data = await GetLevel2(status, id, id1);
        if (data && data.statusCode === 200) {
            const numAscending = data.data;
            setLevel2Menu(numAscending);
        } else {
            setLevel2Menu([]);
        }
    };

    const toggleMegaMenu = (index, _id) => {
        fetchMenuL1(_id);
        if (activeLink === index) {
            setMegaMenuOpen(!megaMenuOpen);
        } else {
            setMegaMenuOpen(true);
            setActiveLink(index);
        }
    };
    // Login Modal 
    const setRegisterDetailsValue2 = (key, value) => {
        setRegisterDetails2({ ...registerDetail2, [key]: value });
        if (registerValidation2[key]) delete registerValidation2[key];
    };
    const setRegisterValidationValue2 = (key, value) => {
        setRegisterValidation2({ ...registerValidation2, [key]: value });
    };
    const setRegisterDetailsValue1 = (key, value) => {
        setRegisterDetails1({ ...registerDetail1, [key]: value });
        if (registerValidation1[key]) delete registerValidation1[key];
    };
    const handleSubmitNewsLetter = async (e) => {
        e.preventDefault();

        const Payload = {
            fullName: state.auth.registerData.name,
            emailId: state.auth.registerData.email,
        };
        const token = state?.auth?.auth?.token;
        const response = await CreateNewsLetter(Payload, token);
        if (response && response.statusCode === 200) {
            // showToastSuccessMsg(response.message);
        } else {
            // showToastErrorMsg(
            //   response.message || response.error.response.data.message
            // );
        }
    };
    const handleSubmit1 = async (e) => {
        e.preventDefault();
        let validate = {};
        validate.email = EmailValidation(registerDetail1?.email?.toLowerCase());
        validate.password = PasswordValidation(registerDetail1?.password);
        setRegisterValidation1(validate);
        if (Object.values(validate).every((v) => v.status === true)) {
            const Payload = {
                email: registerDetail1.email?.toLowerCase(),
                password: registerDetail1.password,
            };

            const response = await UserLogin(Payload);
            if (response && response.statusCode === 200) {
                showToastSuccessMsg(response.message);
                dispatch(loginSuccess(response.data, response?.data?.token));
                setloginModal(false);
                setRegisterDetails1({});
                // navigate("/");
                // window.location.reload();
            } else {
                showToastErrorMsg(
                    response.message || response.error.response.data.message
                );
            }
        } else {
            //console.log("Error while login as user");
        }
    };
    const setRegisterValidationValue1 = (key, value) => {
        setRegisterValidation1({ ...registerValidation1, [key]: value });
    };
    const setRegisterDetailsValue3 = (key, value) => {
        setRegisterDetails3({ ...registerDetail3, [key]: value });
        if (registerValidation3[key]) delete registerValidation3[key];
    };
    const setRegisterValidationValue3 = (key, value) => {
        setRegisterValidation3({ ...registerValidation3, [key]: value });
    };
    const handleSubmitForgotPassword = async (e) => {
        e.preventDefault();
        let validate = {};
        validate.email = EmailValidation(registerDetail2?.email?.toLowerCase());
        setRegisterValidation2(validate);
        if (Object.values(validate).every((v) => v.status === true)) {
            const Payload = {
                email: registerDetail2.email?.toLowerCase(),
            };

            const response = await ForgotPassword(Payload);
            if (response && response.statusCode === 200) {
                dispatch(registerDetails(registerDetail2));
                showToastSuccessMsg(response.message);
                setfpwModal(false);
                setotpFgtModal(true);
                setRegisterDetails2({});
            } else {
                showToastErrorMsg(
                    response.message || response.error.response.data.message
                );
                //setfailureModal(true);
            }
        } else {
            //console.log("Error while reset password");
        }
    };
    const getUserProfile = async () => {
        const id = state?.auth?.auth?._id;
        const token = state.auth.auth.token;
        const response = await GetUser(token, id);
        if (response.statusCode === 200) {
            setUserProfile(response.data);
        } else {
            //console.log("error while getting user profile info");
        }
    };
    const handleChange = (code) => {
        setOtp(code);
        setValidation({});
    };
    const viewPassword = () => {
        setPassword(!password);
    };
    const [password2, setPassword2] = useState();
    const viewPassword2 = () => {
        setPassword2(!password2);
    };
    // state values
    const state = useSelector((state) => state);
    const [passwordStrength, setPasswordStrength] = useState("");
    const [registerDetail, setRegisterDetails] = useState({});
    const [registerValidation, setRegisterValidation] = useState({});
    const setRegisterDetailsValue = (key, value) => {
        setRegisterDetails({ ...registerDetail, [key]: value });
        if (registerValidation[key]) delete registerValidation[key];
    };
    const setRegisterValidationValue = (key, value) => {
        setRegisterValidation({ ...registerValidation, [key]: value });
    };
    // otp
    const handleSubmitOtp = async (data, value) => {
        data.preventDefault();
        let validate = {};
        validate.code = NonEmptyValidation(code);
        setValidation(validate);
        if (Object.values(validate).every((v) => v.status === true)) {
            const Payload = {
                email: state.auth.registerData.email,
                otp: parseInt(code),
            };
            let response = await VerifyOtp(Payload);
            if (response && response.statusCode === 200) {
                setotpModal(false);
                if (state?.auth?.registerData?.acceptNewsLetter === true) {
                    handleSubmitNewsLetter(data);
                }

                setOtp("");
                setloginModal(true);
                showToastSuccessMsg(response.message);
            } else {
                showToastErrorMsg(
                    response.message || response.error.response.data.message
                );
                // setFailureModal(true);
            }
        } else {
            //console.log("Error while verify otp");
        }
    };
    const dispatch = useDispatch();
    // const location = useLocation();
    //error
    const showToastErrorMsg = (errMsg) => {
        toast.error(errMsg, {
            position: toast.POSITION.TOP_RIGHT,
            className: "toast-message",
            autoClose: 5000,
        });
    };
    //success
    const showToastSuccessMsg = (successMsg) => {
        toast.success(successMsg, {
            position: toast.POSITION.TOP_RIGHT,
            className: "toast-message",
            autoClose: 3000,
        });
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        let validate = {};
        validate.name = StringValidation(registerDetail?.name);
        validate.email = EmailValidation(registerDetail?.email?.toLowerCase());
        validate.password = PasswordValidation(registerDetail?.password);
        validate.phoneNumber = PhoneNumberValidation(registerDetail?.phoneNumber);
        setRegisterValidation(validate);
        if (Object.values(validate).every((v) => v.status === true)) {
            const Payload = {
                name: registerDetail.name,
                email: registerDetail.email?.toLowerCase(),
                password: registerDetail.password,
                phoneNumber: registerDetail.phoneNumber,
                role: "user",
            };

            const response = await SignUp(Payload);
            if (response && response.statusCode === 200) {
                showToastSuccessMsg(response.message);
                setcreateModal(false);
                setRegisterDetails({});
                setRegisterValidationValue({});
                setotpModal(true);
                dispatch(registerDetails(registerDetail));
            } else {
                showToastErrorMsg(
                    response.message || response.error.response.data.message
                );
                //setfailureModal(true);
            }
        } else {
            //console.log("Error while register as user");
        }
    };
    const handleSubmitResend = async (e) => {
        e.preventDefault();
        const Payload = {
            name: state.auth.registerData.name,
            email: state.auth.registerData.email,
            password: state.auth.registerData.password,
            phoneNumber: state.auth.registerData.phoneNumber,
            role: "user",
        };

        const response = await SignUp(Payload);
        if (response && response.statusCode === 200) {
            showToastSuccessMsg(response.message);
            setMinutes(0);
            setSeconds(59);
        } else {
            showToastErrorMsg(
                response.message || response.error.response.data.message
            );
            //setfailureModal(true);
        }
    };
    const handleSubmitResend1 = async (e) => {
        e.preventDefault();
        const Payload = {
            email: state.auth.registerData.email,
        };

        const response = await ForgotPassword(Payload);
        if (response && response.statusCode === 200) {
            showToastSuccessMsg(response.message);
            setMinutes(0);
            setSeconds(59);
        } else {
            showToastErrorMsg(
                response.message || response.error.response.data.message
            );
            //setfailureModal(true);
        }
    };
    const handleSubmitChangePassword = async (e) => {
        e.preventDefault();
        let validate = {};
        validate.password = PasswordValidation(registerDetail3?.password);
        validate.re_password = RePasswordValidation(
            registerDetail3?.re_password,
            registerDetail3?.password
        );
        setRegisterValidation3(validate);
        if (Object.values(validate).every((v) => v.status === true)) {
            const Payload = {
                email: state.auth.registerData.email,
                password: registerDetail3?.password,
            };

            const response = await ChangePassword(Payload);
            if (response && response.statusCode === 200) {
                showToastSuccessMsg(response.message);
                setchangepswModal(false);
                setloginModal(true);
                setRegisterDetails3({});
                getUserProfile();
            } else {
                showToastErrorMsg(
                    response.message || response.error.response.data.message
                );
                //setfailureModal(true);
            }
        } else {
            //console.log("Error while chenge password");
        }
    };
    const handleSubmitOtpFgt = async (data) => {
        data.preventDefault();
        let validate = {};
        validate.code = NonEmptyValidation(code);
        setValidation(validate);
        if (Object.values(validate).every((v) => v.status === true)) {
            const Payload = {
                email: state.auth.registerData.email,
                otp: parseInt(code),
            };
            let response = await ForgotOtp(Payload);
            if (response && response.statusCode === 200) {
                showToastSuccessMsg(response.message);

                setotpFgtModal(false);
                setchangepswModal(true);
                setOtp("");
            } else {
                showToastErrorMsg(
                    response.message || response.error.response.data.message
                );
                // setFailureModal(true);
            }
        }
    };
    const [isMenuVisible, setIsMenuVisible] = useState(false);
    const toggleMenu = () => {
        setIsMenuVisible(!isMenuVisible);
    };

    const [isContactVisible, setIsContactVisible] = useState(false);
    const toggleContactMenu = () => {
        setIsContactVisible(!isContactVisible);
    };
    return (
        <>
            <div className={`sidemenu-wrapper ${isContactVisible ? 'show' : ''}`}>
                <div class="sidemenu-content"><button class="closeButton sideMenuCls" onClick={toggleContactMenu}><i class="far fa-times"></i></button>
                    <div class="widget woocommerce widget_shopping_cart">
                        <h3 style={{ fontSize: '22px' }}>Reach us at </h3>
                        <div className="contact-info">
                            <a className={styles.navLink} href="tel:18003098445"><i className="fas fa-mobile-alt" ></i><span style={{ marginLeft: "5px" }}> 1800-309-8445</span></a>
                            <a className={styles.navLink} href="tel:+919884211885"><i className="fas fa-mobile-alt" ></i><span style={{ marginLeft: "5px" }}> +91 9884211885</span></a>
                            <a className={styles.navLink} href="mailto:info@dalmaf.com"><i className="fas fa-envelope" ></i><span style={{ marginLeft: "5px" }}> info@dalmaf.com</span></a>
                            <div className="follow-us">
                                <span className={styles.navLink}>Follow us on:</span>
                                <a className={styles.navLink} href="https://www.instagram.com/dalmaf_dpl?igsh=M2R0bGI0MjNvZ2E3" target='_blank'><i className="fab fa-instagram" ></i></a>
                                <a className={styles.navLink} href="https://play.google.com/store/apps/details?id=com.dalmaf" target='_blank'><i className="fab fa-google-play" ></i></a>
                                <a className={styles.navLink} href="https://apps.apple.com/in/app/dalmaf/id6467872452" target='_blank'><i className="fab fa-app-store-ios" ></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Mobile Version Start */}
            <div className={`th-menu-wrapper ${isMenuVisible ? 'th-body-visible' : ''}`}>
                <div className="th-menu-area text-center">
                    <button className="th-menu-toggle" onClick={toggleMenu}>
                        <i className="fal fa-times"></i>
                    </button>
                    <div className="mobile-logo">
                        <span onClick={(e) => { e.preventDefault(); navigate('/') }}>
                            <img src={NewLog} alt="Dalmaf" style={{ width: '70%' }} />
                        </span>
                    </div>
                    <div className="th-mobile-menu">
                        <ul>
                            {
                                mainHeader?.map((nav, i) => (
                                    <li><span className={styles.navLink} key={i} onClick={() => { window.scrollTo(0, 0); toggleMenu(); toggleMegaMenu(i, nav?._id); setMainTitle(nav?.title); setMainId(nav?._id); localStorage?.setItem("mainContent", nav._id); }}>{nav?.title}</span></li>
                                ))
                            }
                            <li><span className={styles.navLink} onClick={(e) => { e.preventDefault(); toggleMenu(); navigate('/dalmaf-schemes') }}>Plans</span></li>
                            {/* <li><span className={styles.navLink} onClick={(e) => { e.preventDefault(); toggleMenu(); navigate('/partner-with-dalmaf') }}>Partner with Us</span></li> */}
                            <li><span className={styles.navLink} onClick={(e) => { e.preventDefault(); toggleMenu(); navigate('/business-associate-with-dalmaf') }}>Become Business Associate</span></li>
                            <li><span className={styles.navLink} onClick={(e) => { e.preventDefault(); toggleMenu(); navigate('/About') }}>About Us</span></li>
                            <li><span className={styles.navLink} onClick={(e) => { e.preventDefault(); toggleMenu(); navigate('/Career') }}>Career</span></li>
                            <li><span className={styles.navLink} onClick={(e) => { e.preventDefault(); toggleMenu(); navigate('/Contact') }}>Contact Us</span></li>
                            <li><span className={styles.navLink} onClick={(e) => { e.preventDefault(); toggleMenu(); navigate('/Privacy-Policy') }}>Privacy Policy</span></li>
                            <li><a href="" onClick={(e) => { e.preventDefault(); navigate('/schedule-meet') }} className="th-btn shadow-none" style={{ color: 'white' }}>Make Appointment<i className="fas fa-arrow-right ms-2"></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>
            {/* Mobile Version End */}
            {/* Web Version Start */}
            <header className="th-header header-layout3 fixed-header">
                <div className="sticky-wrapper">
                    <div className="menu-area">
                        <div className="container">
                            <div className="row align-items-center justify-content-between">
                                <div className="col-auto">
                                    <div className="header-logo">
                                        <span onClick={() => { window.scrollTo(0, 0); navigate('/') }}>
                                            <img src={NewLog} alt="dalmaf" />
                                        </span>
                                    </div>
                                </div>
                                <div className="col-auto">
                                    <nav className="main-menu d-none d-lg-inline-block">
                                        <ul>
                                            <li className="menu-item-has-children"><span className={styles.navLink}>Company</span>
                                                <ul className="sub-menu">
                                                    {/* <li><span className={styles.navLink} onClick={(e) => { e.preventDefault(); navigate('/partner-with-dalmaf') }}>Partner with Us</span></li> */}
                                                    <li><span className={styles.navLink} onClick={(e) => { e.preventDefault(); navigate('/business-associate-with-dalmaf') }}>Become Business Associate</span></li>
                                                    <li><span className={styles.navLink} onClick={(e) => { e.preventDefault(); navigate('/About') }}>About Us</span></li>
                                                    <li><span className={styles.navLink} onClick={(e) => { e.preventDefault(); navigate('/Career') }}>Career</span></li>
                                                    <li><span className={styles.navLink} onClick={(e) => { e.preventDefault(); navigate('/Contact') }}>Contact Us</span></li>
                                                    <li><span className={styles.navLink} onClick={(e) => { e.preventDefault(); navigate('/Privacy-Policy') }}>Privacy Policy</span></li>
                                                </ul>
                                            </li>
                                            {
                                                mainHeader?.map((nav, i) => (
                                                    <li><span className={styles.navLink} key={i} onClick={() => { window.scrollTo(0, 0); toggleMegaMenu(i, nav?._id); setMainTitle(nav?.title); setMainId(nav?._id); localStorage?.setItem("mainContent", nav._id); }}>{nav?.title}</span></li>
                                                ))
                                            }
                                            <li><span className={styles.navLink} onClick={(e) => { e.preventDefault(); navigate('/dalmaf-schemes') }}>Plans</span></li>

                                        </ul>
                                    </nav>
                                    <div className="header-button">
                                        {/* <button type="button" className="icon-btn sideMenuToggler d-inline-block d-lg-none" onClick={toggleContactMenu}>
                                            <i className="far fa-shopping-cart"></i> 
                                            <span className="badge">9</span>
                                        </button>  */}
                                        {state?.auth?.isLoggedIn === true ? (
                                            <>
                                                <span
                                                    className="nav-link d-inline-block d-lg-none"
                                                    style={{ cursor: "pointer" }}
                                                    onClick={() => {
                                                        if (state?.auth?.auth?.role === "freelancer") {
                                                            navigate("/entrepreneurui/dashboard");
                                                        } else if (state?.auth?.auth?.role === "partner") {
                                                            navigate("/partner/dashboard");
                                                        } else {
                                                            navigate("/Dashboard");
                                                        }
                                                        // 
                                                    }}
                                                >
                                                    {" "}
                                                    {/* <span className="material-symbols-rounded align-middle icon-bg">
                                                        person{" "}
                                                    </span> */}
                                                    {state?.auth?.auth?.name} !
                                                </span>
                                            </>
                                        ) : (<>
                                            <button className='th-btn d-inline-block d-lg-none'
                                                // onClick={()=>{setLoginModalInput(true);setSignupModalInput(false)}}
                                                onClick={() => {
                                                    setcreateModal(false);
                                                    setloginModal(true);
                                                    setRegisterDetails({});
                                                    setRegisterValidation({});
                                                }}
                                            >Login/Signup</button>
                                            {/* <button className='th-btn'
                                                    // onClick={()=>{setLoginModalInput(false);setSignupModalInput(true)}}
                                                    onClick={() => {
                                                        setloginModal(false);
                                                        setcreateModal(true);
                                                        setRegisterDetails1({});
                                                        setRegisterValidation1({});
                                                    }}
                                                >Signup</button> */}
                                        </>)
                                        }
                                        <button type="button" className="th-menu-toggle d-inline-block d-lg-none" onClick={toggleMenu}>
                                            <i className="far fa-bars"></i>
                                        </button>
                                    </div>
                                </div>
                                <div className="col-auto d-none d-lg-block">
                                    <div className="header-button">
                                        {/* <a href="" onClick={(e) => { e.preventDefault(); navigate('/schedule-meet') }} className="th-btn shadow-none">Make Appointment
                                            <i className="fas fa-arrow-right ms-2"></i>
                                        </a> */}
                                        {/* <button type="button" className="icon-btn sideMenuToggler" onClick={toggleContactMenu}>
                                            <i className="far fa-magnifying-glass"></i>
                                            <span className="badge">10</span>
                                        </button> */}
                                        {state?.auth?.isLoggedIn === true ? (
                                            <>
                                                <span
                                                    className="nav-link"
                                                    style={{ cursor: "pointer" }}
                                                    onClick={() => {
                                                        if (state?.auth?.auth?.role === "freelancer") {
                                                            navigate("/entrepreneurui/dashboard");
                                                        } else if (state?.auth?.auth?.role === "partner") {
                                                            navigate("/partner/dashboard");
                                                        } else {
                                                            navigate("/Dashboard");
                                                        }
                                                        // 
                                                    }}
                                                >
                                                    {" "}
                                                    {/* <span className="material-symbols-rounded align-middle icon-bg">
                                                        person{" "}
                                                    </span> */}
                                                    {state?.auth?.auth?.name} !
                                                </span>
                                            </>
                                        ) : (<>
                                            <button className='th-btn'
                                                // onClick={()=>{setLoginModalInput(true);setSignupModalInput(false)}}
                                                onClick={() => {
                                                    setcreateModal(false);
                                                    setloginModal(true);
                                                    setRegisterDetails({});
                                                    setRegisterValidation({});
                                                }}
                                            >Login/Signup</button>
                                            {/* <button className='th-btn'
                                                    // onClick={()=>{setLoginModalInput(false);setSignupModalInput(true)}}
                                                    onClick={() => {
                                                        setloginModal(false);
                                                        setcreateModal(true);
                                                        setRegisterDetails1({});
                                                        setRegisterValidation1({});
                                                    }}
                                                >Signup</button> */}
                                        </>)
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
            {/* Web Version End */}
            {(megaMenuOpen && !isMobile) && (
                <div className="mega-menu open">
                    <Container>
                        <div className="row">
                            <div className="col">
                                <div className='row'>
                                    <div className='col-md-6'>
                                        <h5>{mainTitle}</h5>
                                    </div>
                                    <div className='col-md-6'>
                                        <button
                                            className="close-button"
                                            onClick={() => setMegaMenuOpen(false)}
                                            style={{ float: 'right', border: 'none', background: 'none' }}
                                        >
                                            <i className="fas fa-times" style={{ fontSize: '24px', color: 'black' }}></i>
                                        </button>
                                    </div>
                                </div>


                                <div className="row">
                                    <div className="col-md-3 scrollable-column level1-container">
                                        {
                                            level1Menu?.length == 0 &&
                                            <><div className="level1-menu skeleton-card mb-2"></div>
                                                <div className="level1-menu skeleton-card mb-2"></div>
                                                <div className="level1-menu skeleton-card mb-2"></div>
                                                <div className="level1-menu skeleton-card mb-2"></div></>
                                        }
                                        {level1Menu?.map((nav, index) => (
                                            <Nav.Link
                                                key={index}
                                                onClick={() => { fetchMenuL2("active", mainId, nav?._id); setLevel1Title(nav?.title); }}
                                                className={nav?.title === level1Title ? "level1-menu active" : "level1-menu"}
                                            >
                                                {nav?.title}
                                            </Nav.Link>
                                        ))}
                                    </div>

                                    <div className="col-md-9 scrollable-column level2-container">
                                        {/* <h5 className="level2-title">{level1Title}</h5> */}
                                        <div className="row row-cols-2">
                                            {level2Menu.map((nav, index) => (
                                                <div className="col" key={index}>
                                                    {nav?.hover_content?.length ? (
                                                        <OverlayTrigger
                                                            trigger="hover"
                                                            placement="auto" // Automatically decides the best position
                                                            flip={true}      // Allows the popover to flip if it goes out of view
                                                            overlay={
                                                                <Popover id={`popover-${index}`} className="custom-popover">
                                                                    <Popover.Body>
                                                                        <ul className="hover-content-list">
                                                                            {nav.hover_content.map((item, i) => (
                                                                                <li key={i}>{item}</li>
                                                                            ))}
                                                                        </ul>
                                                                    </Popover.Body>
                                                                </Popover>
                                                            }
                                                        >
                                                            <Nav.Link
                                                                onClick={() => {
                                                                    setMegaMenuOpen(false);
                                                                    navigate({
                                                                        pathname: "/Service",
                                                                        search: `tab=${nav.mainServiceId.title}&subtab=${nav.subServiceL1Id.title} | ${nav.title}&id=${nav._id}`,
                                                                    });
                                                                    window.location.reload();
                                                                }}
                                                                className="level2-menu"
                                                            >
                                                                {nav.title}
                                                            </Nav.Link>
                                                        </OverlayTrigger>
                                                    ) : (
                                                        <Nav.Link
                                                            onClick={() => {
                                                                setMegaMenuOpen(false);
                                                                navigate({
                                                                    pathname: "/Service",
                                                                    search: `tab=${nav.mainServiceId.title}&subtab=${nav.subServiceL1Id.title} | ${nav.title}&id=${nav._id}`,
                                                                });
                                                                window.location.reload();
                                                            }}
                                                            className="level2-menu"
                                                        >
                                                            {nav.title}
                                                        </Nav.Link>
                                                    )}
                                                </div>
                                            ))}

                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </Container>
                </div>
            )}
            {(megaMenuOpen && isMobile) && (
                <div className="mega-menu open">
                    <Container>
                        <div className="row">
                            <div className="col">
                                <div className='row'>
                                    <div className='col-md-6'>
                                        <h5>{mainTitle}</h5>
                                    </div>
                                    <div className='col-md-6'>
                                        {
                                            isShowLevel2 && (
                                                <button
                                                    onClick={() => {
                                                        setIsShowLevel2(false);
                                                        setIsShowLevel1(true);
                                                    }}
                                                    style={{ border: 'none', background: 'none' }} // Optional: remove default button styles
                                                >
                                                    <i className="fas fa-arrow-left" style={{ fontSize: '20px', color: 'black' }}></i> {/* Font Awesome Back Icon */}
                                                </button>
                                            )
                                        }

                                        <button
                                            className="close-button"
                                            onClick={() => setMegaMenuOpen(false)}
                                            style={{ float: 'right', border: 'none', background: 'none' }}
                                        >
                                            <i className="fas fa-times" style={{ fontSize: '24px', color: 'black' }}></i>
                                        </button>
                                    </div>
                                </div>

                                {
                                    isShowLevel1 && (
                                        <div className="row">
                                            <div className="col-md-12 scrollable-column level1-container">
                                                {
                                                    level1Menu?.length === 0 && (
                                                        <>
                                                            <div className="level1-menu skeleton-card mb-2"></div>
                                                            <div className="level1-menu skeleton-card mb-2"></div>
                                                            <div className="level1-menu skeleton-card mb-2"></div>
                                                            <div className="level1-menu skeleton-card mb-2"></div>
                                                        </>
                                                    )
                                                }
                                                <div className='row'>
                                                    {level1Menu?.map((nav, index) => (
                                                        <div className='col-12 col-sm-6 col-md-6 col-lg-6' key={index}>
                                                            <Nav.Link
                                                                onClick={() => {
                                                                    setIsShowLevel2(true)
                                                                    setIsShowLevel1(false)
                                                                    fetchMenuL2("active", mainId, nav?._id);
                                                                    setLevel1Title(nav?.title);
                                                                }}
                                                            // className={nav?.title === level1Title ? "level1-menu active" : "level1-menu"}
                                                            >
                                                                {nav?.title}
                                                            </Nav.Link>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }

                                {
                                    isShowLevel2 && (
                                        <div className='row'>
                                            <div className="col-md-9 scrollable-column level2-container">
                                                <div className="row row-cols-1">
                                                    {level2Menu?.map((nav, index) => (
                                                        <div className="col" key={index}>
                                                            <Nav.Link
                                                                onClick={
                                                                    () => {
                                                                        setIsShowLevel2(false)
                                                                        setIsShowLevel1(true)
                                                                        setMegaMenuOpen(false);
                                                                        navigate({
                                                                            pathname: "/Service",
                                                                            search: `tab=${nav.mainServiceId.title}&subtab=${nav.subServiceL1Id.title} | ${nav.title}&id=${nav._id}`,
                                                                        });
                                                                        window.location.reload();
                                                                    }
                                                                }
                                                            // className="level2-menu"
                                                            >{nav?.title}</Nav.Link>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }





                                {/* </div> */}

                            </div>
                        </div>
                    </Container>
                </div>
            )}


            <div className="contact-footer d-block d-lg-none" style={{ backgroundColor: 'white', zIndex: '9999' }}>
                <Container>
                    <div className="d-flex justify-content-between">
                        <a href="tel:18003098445" className="btn btn-primary"><i className="fas fa-mobile-alt"></i><span>1800-309-8445</span></a>
                        <a href="tel:+919884211885" className="btn btn-secondary"><i className="fas fa-mobile-alt"></i><span>+91 9884211885</span></a>
                    </div>
                </Container>
            </div>
            {/* {
                signupModalInput || loginModalInput &&
                <UserBasic loginModalInput={loginModalInput} signupModalInput={signupModalInput} />
            } */}
            <>
                {/* Login */}
                <CustomModal
                    open={loginModal}
                    onClickOutside={() => {
                        setloginModal(false);
                        setRegisterValidation1({});
                        setcreateModal(false);
                        setfpwModal(false);
                        setotpModal(false);
                        setchangepswModal(false);
                    }}
                >
                    <div className="modal-content w-50 m-auto">
                        <div className="modal-body p-0 ">
                            <div className="container ">
                                <span
                                    className="material-symbols-rounded filled-icon close-icon"
                                    style={{
                                        marginRight: "30px",
                                        marginTop: "50px",
                                        textShadow: "rgb(171 167 167 / 44%) 0px 3px 6px",
                                    }}
                                    onClick={() => {
                                        setloginModal(false);
                                    }}
                                >
                                    close
                                </span>
                                <div className="row d-flex align-items-center justify-content-center  mt-0">
                                    <div className="container ">
                                        <div className="row justify-content-center">
                                            <div className="col-lg-12">
                                                <div className=" my-5 px-3">
                                                    <div className="row justify-content-center mt-2">
                                                        <div className="col-lg-10">
                                                            <div className="mb-5">
                                                                <h4 className="fw-bold mt-2">Login</h4>
                                                            </div>
                                                            <div className="mb-3">
                                                                <label for="" className="form-label">
                                                                    Email
                                                                </label>

                                                                <input
                                                                    type="email"
                                                                    className="form-control"
                                                                    placeholder="example@mail.com"
                                                                    required
                                                                    aria-invalid={Boolean(
                                                                        registerValidation1?.email?.status === false
                                                                    )}
                                                                    defaultValue={
                                                                        registerDetail1?.email?.toLowerCase() || ""
                                                                    }
                                                                    onChange={(e) => {
                                                                        setRegisterDetailsValue1(
                                                                            "email",
                                                                            e.target.value?.toLowerCase()
                                                                        );
                                                                    }}
                                                                    onBlur={(e) => {
                                                                        setRegisterValidationValue1(
                                                                            "email",
                                                                            EmailValidation(
                                                                                e.target.value?.toLowerCase()
                                                                            )
                                                                        );
                                                                    }}
                                                                    autoComplete="off"
                                                                />
                                                                <small className="text-danger">
                                                                    {registerValidation1?.email?.message
                                                                        ? `Email ${registerValidation1?.email?.message}`
                                                                        : ""}
                                                                </small>
                                                            </div>
                                                            <div className="mb-3 ">
                                                                <div className="psw-icon-section">
                                                                    <label for="" className="form-label">
                                                                        Password
                                                                    </label>
                                                                    <input
                                                                        type={password ? "text" : "password"}
                                                                        className="form-control"
                                                                        required
                                                                        placeholder="******"
                                                                        aria-invalid={Boolean(
                                                                            registerValidation1?.password?.status ===
                                                                            false
                                                                        )}
                                                                        defaultValue={registerDetail1?.password || ""}
                                                                        onChange={(e) => {
                                                                            setRegisterDetailsValue1(
                                                                                "password",
                                                                                e.target.value
                                                                            );
                                                                        }}
                                                                        onBlur={(e) => {
                                                                            setRegisterValidationValue1(
                                                                                "password",
                                                                                PasswordValidation(e.target.value)
                                                                            );
                                                                        }}
                                                                        autoComplete="off"
                                                                    />
                                                                    <div className="psw-icon">
                                                                        {password ? (
                                                                            <span
                                                                                className="material-symbols-rounded cursor align-middle"
                                                                                onClick={() => viewPassword()}
                                                                            >
                                                                                visibility
                                                                            </span>
                                                                        ) : (
                                                                            <span
                                                                                className="material-symbols-rounded cursor align-middle"
                                                                                onClick={() => viewPassword()}
                                                                            >
                                                                                visibility_off
                                                                            </span>
                                                                        )}
                                                                    </div>
                                                                </div>

                                                                <small className="text-danger">
                                                                    {registerValidation1?.password?.message
                                                                        ? `Password ${registerValidation1?.password?.message}`
                                                                        : ""}
                                                                </small>
                                                            </div>
                                                            <div className="mb-3">
                                                                <button
                                                                    type="button"
                                                                    className="btn  w-100 my-2  login-btn"
                                                                    onClick={(e) => handleSubmit1(e)}
                                                                >
                                                                    Login
                                                                </button>
                                                            </div>
                                                            <div className="mb-3 ">
                                                                <p className="text-center mb-1">
                                                                    <span className="text-secondary">
                                                                        Don’t have account? {" "}
                                                                    </span>
                                                                    <span
                                                                        className="mx-2 cursor text-decoration-underline"
                                                                        onClick={() => {
                                                                            setloginModal(false);
                                                                            setcreateModal(true);
                                                                            setRegisterDetails1({});
                                                                            setRegisterValidation1({});
                                                                        }}
                                                                    >
                                                                        Sign Up
                                                                    </span>{" "}
                                                                </p>
                                                                <p className="text-center">
                                                                    <span className="text-secondary">
                                                                        Forgot your password?
                                                                    </span>
                                                                    <span
                                                                        className="mx-2 cursor text-decoration-underline"
                                                                        onClick={() => {
                                                                            setloginModal(false);
                                                                            setfpwModal(true);
                                                                            setRegisterDetails1({});
                                                                            setRegisterValidation1({});
                                                                        }}
                                                                    >
                                                                        Reset
                                                                    </span>{" "}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </CustomModal>
                {/* Signup */}
                <CustomModal
                    open={createModal}
                    onClickOutside={() => {
                        setcreateModal(false);
                        setRegisterValidation({});
                        setfpwModal(false);
                        setotpModal(false);
                        setloginModal(false);
                        setchangepswModal(false);
                    }}
                >
                    <div className="modal-content w-50 h-50  m-auto">
                        <div className="modal-body p-0 ">
                            <div className="container">
                                <span
                                    className="material-symbols-rounded filled-icon close-icon"
                                    style={{
                                        marginRight: "30px",
                                        marginTop: "50px",
                                        textShadow: "rgb(171 167 167 / 44%) 0px 3px 6px",
                                    }}
                                    onClick={() => {
                                        setcreateModal(false);
                                    }}
                                >
                                    close
                                </span>
                                <div className="row d-flex align-items-center justify-content-center  mt-0">
                                    <div className="container ">
                                        <div className="row justify-content-center">
                                            <div className="col-lg-12">
                                                <div className=" my-5 px-3">
                                                    <div className="row justify-content-center mt-2">
                                                        <div className="col-lg-10 reg-page-scroll">
                                                            <div className="mb-5">
                                                                <h4 className="fw-bold mt-2">
                                                                    Create an account
                                                                </h4>
                                                            </div>
                                                            <div className="mb-3">
                                                                <label for="" className="form-label">
                                                                    Name
                                                                </label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control"
                                                                    placeholder="Name"
                                                                    required
                                                                    aria-invalid={Boolean(
                                                                        registerValidation?.name?.status === false
                                                                    )}
                                                                    defaultValue={registerDetail?.name || ""}
                                                                    onChange={(e) => {
                                                                        setRegisterDetailsValue(
                                                                            "name",
                                                                            e.target.value
                                                                        );
                                                                    }}
                                                                    onBlur={(e) => {
                                                                        setRegisterValidationValue(
                                                                            "name",
                                                                            StringValidation(e.target.value)
                                                                        );
                                                                    }}
                                                                    autoComplete="off"
                                                                />
                                                                <small className="text-danger">
                                                                    {registerValidation?.name?.message
                                                                        ? `Name ${registerValidation?.name?.message}`
                                                                        : ""}
                                                                </small>
                                                            </div>
                                                            <div className="mb-3">
                                                                <label for="" className="form-label">
                                                                    Email
                                                                </label>
                                                                <input
                                                                    type="email"
                                                                    className="form-control"
                                                                    placeholder="example@mail.com"
                                                                    required
                                                                    aria-invalid={Boolean(
                                                                        registerValidation?.email?.status === false
                                                                    )}
                                                                    defaultValue={
                                                                        registerDetail?.email?.toLowerCase() || ""
                                                                    }
                                                                    onChange={(e) => {
                                                                        setRegisterDetailsValue(
                                                                            "email",
                                                                            e.target.value?.toLowerCase()
                                                                        );
                                                                    }}
                                                                    onBlur={(e) => {
                                                                        setRegisterValidationValue(
                                                                            "email",
                                                                            EmailValidation(
                                                                                e.target.value?.toLowerCase()
                                                                            )
                                                                        );
                                                                    }}
                                                                    autoComplete="off"
                                                                />
                                                                <small className="text-danger">
                                                                    {registerValidation?.email?.message
                                                                        ? `Email ${registerValidation?.email?.message}`
                                                                        : ""}
                                                                </small>
                                                            </div>
                                                            <div className="mb-3">
                                                                <label for="" className="form-label">
                                                                    Phone No
                                                                </label>
                                                                <input
                                                                    type="tel"
                                                                    className="form-control"
                                                                    maxLength={10}
                                                                    placeholder="Phone Number"
                                                                    required
                                                                    aria-invalid={Boolean(
                                                                        registerValidation?.phoneNumber?.status ===
                                                                        false
                                                                    )}
                                                                    defaultValue={registerDetail?.phoneNumber || ""}
                                                                    onChange={(e) => {
                                                                        setRegisterDetailsValue(
                                                                            "phoneNumber",
                                                                            e.target.value
                                                                        );
                                                                    }}
                                                                    onBlur={(e) => {
                                                                        setRegisterValidationValue(
                                                                            "phoneNumber",
                                                                            PhoneNumberValidation(e.target.value)
                                                                        );
                                                                    }}
                                                                    autoComplete="off"
                                                                />
                                                                <small className="text-danger">
                                                                    {registerValidation?.phoneNumber?.message
                                                                        ? `Phone no ${registerValidation?.phoneNumber?.message}`
                                                                        : ""}
                                                                </small>
                                                            </div>
                                                            <div className="mb-3 ">
                                                                <div className="psw-icon-section">
                                                                    <label for="" className="form-label">
                                                                        Password
                                                                    </label>
                                                                    <input
                                                                        type={password ? "text" : "password"}
                                                                        className="form-control"
                                                                        required
                                                                        placeholder="*********"
                                                                        aria-invalid={Boolean(
                                                                            registerValidation?.password?.status ===
                                                                            false
                                                                        )}
                                                                        defaultValue={registerDetail?.password || ""}
                                                                        onChange={(e) => {
                                                                            setRegisterDetailsValue(
                                                                                "password",
                                                                                e.target.value
                                                                            );
                                                                            setPasswordStrength(e.target.value);
                                                                        }}
                                                                        onBlur={(e) => {
                                                                            setRegisterValidationValue(
                                                                                "password",
                                                                                PasswordValidation(e.target.value)
                                                                            );
                                                                        }}
                                                                        autoComplete="off"
                                                                    />
                                                                    <div className="psw-icon">
                                                                        {password ? (
                                                                            <span
                                                                                className="material-symbols-rounded cursor align-middle"
                                                                                onClick={() => viewPassword()}
                                                                            >
                                                                                visibility
                                                                            </span>
                                                                        ) : (
                                                                            <span
                                                                                className="material-symbols-rounded cursor align-middle"
                                                                                onClick={() => viewPassword()}
                                                                            >
                                                                                visibility_off
                                                                            </span>
                                                                        )}
                                                                    </div>
                                                                </div>

                                                                <small className="text-danger">
                                                                    {registerValidation?.password?.message
                                                                        ? `Password ${registerValidation?.password?.message}`
                                                                        : ""}
                                                                </small>
                                                            </div>
                                                            {passwordStrength ? (
                                                                <div className="mt-3">
                                                                    <b>Create a password with the following</b>
                                                                    <br />
                                                                    {
                                                                        <p
                                                                            className={`${passwordStrength.trim().length < 8
                                                                                ? "text-danger"
                                                                                : "text-success"
                                                                                } d-flex`}
                                                                        >
                                                                            {passwordStrength.trim().length < 8 ? (
                                                                                <span className="material-symbols-rounded filled-icon close-icon">
                                                                                    close
                                                                                </span>
                                                                            ) : (
                                                                                <span className="material-symbols-rounded filled-icon close-icon">
                                                                                    check
                                                                                </span>
                                                                            )}
                                                                            At least 8 Characters
                                                                        </p>
                                                                    }
                                                                    {
                                                                        <p
                                                                            className={`${!/^(?=.*[A-Z])(?=.*[a-z]).*$/.test(
                                                                                passwordStrength
                                                                            )
                                                                                ? "text-danger"
                                                                                : "text-success"
                                                                                } d-flex`}
                                                                        >
                                                                            {!/^(?=.*[A-Z])(?=.*[a-z]).*$/.test(
                                                                                passwordStrength
                                                                            ) ? (
                                                                                <span className="material-symbols-rounded filled-icon close-icon">
                                                                                    close
                                                                                </span>
                                                                            ) : (
                                                                                <span className="material-symbols-rounded filled-icon close-icon">
                                                                                    check
                                                                                </span>
                                                                            )}
                                                                            An Uppercase &amp; Lowercase character
                                                                        </p>
                                                                    }
                                                                    {
                                                                        <p
                                                                            className={`${!/^(?=.*[.,:;\'!@#$%^&*_+=|(){}[?\-\]\/\\]).*$/.test(
                                                                                passwordStrength
                                                                            )
                                                                                ? "text-danger"
                                                                                : "text-success"
                                                                                } d-flex`}
                                                                        >
                                                                            {!/^(?=.*[.,:;\'!@#$%^&*_+=|(){}[?\-\]\/\\]).*$/.test(
                                                                                passwordStrength
                                                                            ) ? (
                                                                                <span className="material-symbols-rounded filled-icon close-icon">
                                                                                    close
                                                                                </span>
                                                                            ) : (
                                                                                <span className="material-symbols-rounded filled-icon close-icon">
                                                                                    check
                                                                                </span>
                                                                            )}
                                                                            A special character
                                                                        </p>
                                                                    }
                                                                    {
                                                                        <p
                                                                            className={`${!/[0-9]/.test(passwordStrength)
                                                                                ? "text-danger"
                                                                                : "text-success"
                                                                                } d-flex`}
                                                                        >
                                                                            {!/[0-9]/.test(passwordStrength) ? (
                                                                                <span className="material-symbols-rounded filled-icon close-icon">
                                                                                    close
                                                                                </span>
                                                                            ) : (
                                                                                <span className="material-symbols-rounded filled-icon close-icon">
                                                                                    check
                                                                                </span>
                                                                            )}
                                                                            A number
                                                                        </p>
                                                                    }
                                                                </div>
                                                            ) : null}
                                                            <div className="mb-3">
                                                                <input
                                                                    // id="email"
                                                                    type="checkbox"
                                                                    // className="form-control"
                                                                    required
                                                                    aria-invalid={Boolean(
                                                                        registerValidation?.acceptNewsLetter
                                                                            ?.status === false
                                                                    )}
                                                                    defaultValue={registerDetail?.acceptNewsLetter}
                                                                    onChange={(e) => {
                                                                        setRegisterDetailsValue(
                                                                            "acceptNewsLetter",
                                                                            e.target.checked
                                                                        );
                                                                    }}
                                                                    onBlur={(e) => {
                                                                        setRegisterValidationValue(
                                                                            "acceptNewsLetter",
                                                                            NonEmptyKeyValidation(e.target.checked)
                                                                        );
                                                                    }}
                                                                    autoComplete="off"
                                                                />{" "}
                                                                <label for="" className="form-label">
                                                                    {" "}
                                                                    I would like to receive news letters
                                                                </label>
                                                                <small className="text-danger">
                                                                    {registerValidation?.acceptNewsLetter?.message
                                                                        ? `Accept News Letter ${registerValidation?.acceptNewsLetter?.message}`
                                                                        : ""}
                                                                </small>
                                                            </div>
                                                            <div className="mb-3">
                                                                <button
                                                                    // type="button"
                                                                    className="btn  w-100 my-2  login-btn"
                                                                    onClick={(e) => handleSubmit(e)}
                                                                >
                                                                    Create account
                                                                </button>
                                                            </div>
                                                            <div className="mb-3 ">
                                                                <p className="text-center mb-1">
                                                                    <span className="text-secondary">
                                                                        Already have an account ?
                                                                    </span>
                                                                    <span
                                                                        className="mx-2 cursor text-decoration-underline"
                                                                        onClick={() => {
                                                                            setcreateModal(false);
                                                                            setloginModal(true);
                                                                            setRegisterDetails({});
                                                                            setRegisterValidation({});
                                                                        }}
                                                                    >
                                                                        Log in
                                                                    </span>{" "}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </CustomModal>
                {/* Forgot Password */}
                <CustomModal
                    open={fpwModal}
                    onClickOutside={() => {
                        setfpwModal(false);
                        setRegisterValidation2({});
                        setcreateModal(false);
                        setotpModal(false);
                        setloginModal(false);
                        setchangepswModal(false);
                    }}
                >
                    <div className="modal-content w-50 m-auto">
                        <div className="modal-body p-0 ">
                            <div className="container">
                                <span
                                    className="material-symbols-rounded filled-icon close-icon"
                                    style={{
                                        marginRight: "30px",
                                        marginTop: "50px",
                                        textShadow: "rgb(171 167 167 / 44%) 0px 3px 6px",
                                    }}
                                    onClick={() => {
                                        setfpwModal(false);
                                    }}
                                >
                                    close
                                </span>
                                <div className="row d-flex align-items-center justify-content-center  mt-0">
                                    <div className="container ">
                                        <div className="row justify-content-center">
                                            <div className="col-lg-12">
                                                <div className=" my-5 px-3">
                                                    <div className="row justify-content-center mt-2">
                                                        <div className="col-lg-10">
                                                            <div className="mb-5">
                                                                <h4 className="fw-bold mt-2">Forgot Password</h4>
                                                            </div>
                                                            <div className="mb-3">
                                                                <label for="email" className="form-label">
                                                                    Email
                                                                </label>
                                                                <input
                                                                    id="email"
                                                                    type="email"
                                                                    className="form-control"
                                                                    placeholder="example@mail.com"
                                                                    required
                                                                    aria-invalid={Boolean(
                                                                        registerValidation2?.email?.status === false
                                                                    )}
                                                                    defaultValue={
                                                                        registerDetail2?.email?.toLowerCase() || ""
                                                                    }
                                                                    onChange={(e) => {
                                                                        setRegisterDetailsValue2(
                                                                            "email",
                                                                            e.target.value?.toLowerCase()
                                                                        );
                                                                    }}
                                                                    onBlur={(e) => {
                                                                        setRegisterValidationValue2(
                                                                            "email",
                                                                            EmailValidation(
                                                                                e.target.value?.toLowerCase()
                                                                            )
                                                                        );
                                                                    }}
                                                                />
                                                                <small className="text-danger">
                                                                    {registerValidation2?.email?.message
                                                                        ? `Email ${registerValidation2?.email?.message}`
                                                                        : ""}
                                                                </small>
                                                            </div>
                                                            <div className="mb-3">
                                                                <button
                                                                    type="button"
                                                                    className="btn  w-100 my-2  login-btn"
                                                                    onClick={(e) => {
                                                                        handleSubmitForgotPassword(e);
                                                                    }}
                                                                >
                                                                    Submit
                                                                </button>
                                                            </div>
                                                            <div className="mb-3 ">
                                                                <p className="text-center mb-1">
                                                                    <span className="text-secondary">
                                                                        Already have an account ?
                                                                    </span>
                                                                    <span
                                                                        className="mx-2 cursor text-decoration-underline"
                                                                        onClick={() => {
                                                                            setfpwModal(false);
                                                                            setloginModal(true);
                                                                            setRegisterDetails2({});
                                                                            setRegisterValidation2({});
                                                                        }}
                                                                    >
                                                                        Log in
                                                                    </span>{" "}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </CustomModal>
                {/* Verify your email ID */}
                <CustomModal
                    open={otpModal}
                    onClickOutside={() => {
                        setotpModal(false);
                        setValidation({});
                        setcreateModal(false);
                        setfpwModal(false);
                        setloginModal(false);
                        setchangepswModal(false);
                    }}
                >
                    <div className="modal-content w-50 m-auto">
                        <div className="modal-body p-0 ">
                            <div className="container">
                                <span
                                    className="material-symbols-rounded filled-icon close-icon"
                                    style={{
                                        marginRight: "30px",
                                        marginTop: "50px",
                                        textShadow: "rgb(171 167 167 / 44%) 0px 3px 6px",
                                    }}
                                    onClick={() => {
                                        setotpModal(false);
                                    }}
                                >
                                    close
                                </span>
                                <div className="row d-flex align-items-center justify-content-center  mt-0">
                                    <div className="container ">
                                        <div className="row justify-content-center">
                                            <div className="col-lg-12">
                                                <div className=" my-5 px-3">
                                                    <div className="row justify-content-center mt-2">
                                                        <div className="col-lg-10">
                                                            <div className="mb-5">
                                                                <h4 className="fw-bold mt-3">
                                                                    Verify your email ID
                                                                </h4>
                                                                <small>Enter the code we just sent to</small>
                                                            </div>
                                                            <div className="mb-4 psw-icon-section">
                                                                <OtpInput
                                                                    className="otpInput"
                                                                    value={code}
                                                                    onChange={handleChange}
                                                                    numInputs={6}
                                                                    separator={
                                                                        <span style={{ width: "8px" }}></span>
                                                                    }
                                                                    isInputNum={true}
                                                                    shouldAutoFocus={true}
                                                                    inputStyle={{
                                                                        border: "1px solid #CFD3DB",
                                                                        borderRadius: "8px",
                                                                        width: "45px",
                                                                        height: "45px",
                                                                        fontSize: "12px",
                                                                        color: "#000",
                                                                        fontWeight: "400",
                                                                        caretColor: "blue",
                                                                    }}
                                                                    focusStyle={{
                                                                        border: "1px solid black ",
                                                                        outline: "none",
                                                                    }}
                                                                />
                                                            </div>
                                                            <small className="text-danger">
                                                                {validation?.code?.message
                                                                    ? `OTP ${validation?.code?.message}`
                                                                    : ""}
                                                            </small>
                                                            {seconds > 0 || minutes > 0 ? (
                                                                <div className="top-12">
                                                                    Resend OTP in&nbsp;
                                                                    <span className="text-green">
                                                                        {minutes < 10 ? `0${minutes}` : minutes}:
                                                                        {seconds < 10 ? `0${seconds}` : seconds}
                                                                    </span>
                                                                </div>
                                                            ) : (
                                                                <div className="top-12">
                                                                    Didn't receive code?&nbsp;
                                                                    <span
                                                                        className="fgtpwd"
                                                                        style={{ cursor: "pointer" }}
                                                                        onClick={(e) => handleSubmitResend(e)}
                                                                    >
                                                                        Resend
                                                                    </span>
                                                                </div>
                                                            )}

                                                            <div className="mb-3">
                                                                <button
                                                                    type="button"
                                                                    className="btn  w-100 my-2  login-btn"
                                                                    onClick={(e) => {
                                                                        if (
                                                                            localStorage.getItem(
                                                                                "accountcreation" === true
                                                                            )
                                                                        ) {
                                                                            handleSubmitOtp(e, "accountcreation");
                                                                        } else {
                                                                            handleSubmitOtp(e, "forgot");
                                                                        }

                                                                        // setchangepswModal(true);
                                                                    }}
                                                                >
                                                                    Verify
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </CustomModal>
                {/* Verify your email ID */}
                <CustomModal
                    open={otpFgtModal}
                    onClickOutside={() => {
                        setotpFgtModal(false);
                        setValidation({});
                        setcreateModal(false);
                        setfpwModal(false);
                        setloginModal(false);
                        setchangepswModal(false);
                    }}
                >
                    <div className="modal-content w-50 m-auto">
                        <div className="modal-body p-0 ">
                            <div className="container">
                                <span
                                    className="material-symbols-rounded filled-icon close-icon"
                                    style={{
                                        marginRight: "30px",
                                        marginTop: "50px",
                                        textShadow: "rgb(171 167 167 / 44%) 0px 3px 6px",
                                    }}
                                    onClick={() => {
                                        setotpFgtModal(false);
                                    }}
                                >
                                    close
                                </span>
                                <div className="row d-flex align-items-center justify-content-center  mt-0">
                                    <div className="container ">
                                        <div className="row justify-content-center">
                                            <div className="col-lg-12">
                                                <div className=" my-5 px-3">
                                                    <div className="row justify-content-center mt-2">
                                                        <div className="col-lg-10">
                                                            <div className="mb-5">
                                                                <h4 className="fw-bold mt-3">
                                                                    Verify your email ID
                                                                </h4>
                                                                <small>Enter the code we just sent to</small>
                                                            </div>
                                                            <div className="mb-4 psw-icon-section">
                                                                <OtpInput
                                                                    className="otpInput"
                                                                    value={code}
                                                                    onChange={handleChange}
                                                                    numInputs={6}
                                                                    separator={
                                                                        <span style={{ width: "8px" }}></span>
                                                                    }
                                                                    isInputNum={true}
                                                                    shouldAutoFocus={true}
                                                                    inputStyle={{
                                                                        border: "1px solid #CFD3DB",
                                                                        borderRadius: "8px",
                                                                        width: "45px",
                                                                        height: "45px",
                                                                        fontSize: "12px",
                                                                        color: "#000",
                                                                        fontWeight: "400",
                                                                        caretColor: "blue",
                                                                    }}
                                                                    focusStyle={{
                                                                        border: "1px solid black ",
                                                                        outline: "none",
                                                                    }}
                                                                />
                                                            </div>
                                                            <small className="text-danger">
                                                                {validation?.code?.message
                                                                    ? `OTP ${validation?.code?.message}`
                                                                    : ""}
                                                            </small>
                                                            {seconds > 0 || minutes > 0 ? (
                                                                <div className="top-12">
                                                                    Resend OTP in&nbsp;
                                                                    <span className="text-green">
                                                                        {minutes < 10 ? `0${minutes}` : minutes}:
                                                                        {seconds < 10 ? `0${seconds}` : seconds}
                                                                    </span>
                                                                </div>
                                                            ) : (
                                                                <div className="top-12">
                                                                    Didn't receive code?&nbsp;
                                                                    <span
                                                                        className="fgtpwd"
                                                                        style={{ cursor: "pointer" }}
                                                                        onClick={(e) => handleSubmitResend1(e)}
                                                                    >
                                                                        Resend
                                                                    </span>
                                                                </div>
                                                            )}

                                                            <div className="mb-3">
                                                                <button
                                                                    type="button"
                                                                    className="btn  w-100 my-2  login-btn"
                                                                    onClick={(e) => {
                                                                        handleSubmitOtpFgt(e);
                                                                    }}
                                                                >
                                                                    Verify
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </CustomModal>
                {/* Change Password */}
                <CustomModal
                    open={changepswModal}
                    onClickOutside={() => {
                        setchangepswModal(false);
                        setRegisterValidation3({});
                        setcreateModal(false);
                        setfpwModal(false);
                        setotpModal(false);
                        setloginModal(false);
                    }}
                >
                    <div className="modal-content w-50 m-auto">
                        <div className="modal-body p-0 ">
                            <div className="container">
                                <span
                                    className="material-symbols-rounded filled-icon close-icon"
                                    style={{
                                        marginRight: "30px",
                                        marginTop: "50px",
                                        textShadow: "rgb(171 167 167 / 44%) 0px 3px 6px",
                                    }}
                                    onClick={() => {
                                        setchangepswModal(false);
                                    }}
                                >
                                    close
                                </span>
                                <div className="row d-flex align-items-center justify-content-center  mt-0">
                                    <div className="container ">
                                        <div className="row justify-content-center">
                                            <div className="col-lg-12">
                                                <div className=" my-5 px-3">
                                                    <div className="row justify-content-center mt-2">
                                                        <div className="col-lg-10 reg-page-scroll">
                                                            <div className="mb-5">
                                                                <h4 className="fw-bold mt-3">Change Password</h4>
                                                            </div>
                                                            <div className="mb-3 ">
                                                                <div className="psw-icon-section">
                                                                    <label for="password" className="form-label">
                                                                        New Password
                                                                    </label>
                                                                    <input
                                                                        id="password"
                                                                        type={password ? "text" : "password"}
                                                                        className="form-control"
                                                                        placeholder="*********"
                                                                        required
                                                                        aria-invalid={Boolean(
                                                                            registerValidation3?.password?.status ===
                                                                            false
                                                                        )}
                                                                        defaultValue={registerDetail?.password || ""}
                                                                        onChange={(e) => {
                                                                            setRegisterDetailsValue3(
                                                                                "password",
                                                                                e.target.value
                                                                            );
                                                                            setPasswordStrength(e.target.value);
                                                                        }}
                                                                        onBlur={(e) => {
                                                                            setRegisterValidationValue3(
                                                                                "password",
                                                                                PasswordValidation(e.target.value)
                                                                            );
                                                                        }}
                                                                        autoComplete="off"
                                                                    />
                                                                    <div className="psw-icon">
                                                                        {password ? (
                                                                            <span
                                                                                className="material-symbols-rounded cursor align-middle"
                                                                                onClick={() => viewPassword()}
                                                                            >
                                                                                visibility
                                                                            </span>
                                                                        ) : (
                                                                            <span
                                                                                className="material-symbols-rounded cursor align-middle"
                                                                                onClick={() => viewPassword()}
                                                                            >
                                                                                visibility_off
                                                                            </span>
                                                                        )}
                                                                    </div>
                                                                </div>

                                                                <small className="text-danger">
                                                                    {registerValidation3?.password?.message
                                                                        ? `Password ${registerValidation3?.password?.message}`
                                                                        : ""}
                                                                </small>
                                                                {passwordStrength ? (
                                                                    <div className="mt-3">
                                                                        <b>Create a password with the following</b>
                                                                        <br />
                                                                        {
                                                                            <p
                                                                                className={`${passwordStrength.trim().length < 8
                                                                                    ? "text-danger"
                                                                                    : "text-success"
                                                                                    } d-flex`}
                                                                            >
                                                                                {passwordStrength.trim().length < 8 ? (
                                                                                    <span className="material-symbols-rounded filled-icon close-icon">
                                                                                        close
                                                                                    </span>
                                                                                ) : (
                                                                                    <span className="material-symbols-rounded filled-icon close-icon">
                                                                                        check
                                                                                    </span>
                                                                                )}
                                                                                At least 8 Characters
                                                                            </p>
                                                                        }
                                                                        {
                                                                            <p
                                                                                className={`${!/^(?=.*[A-Z])(?=.*[a-z]).*$/.test(
                                                                                    passwordStrength
                                                                                )
                                                                                    ? "text-danger"
                                                                                    : "text-success"
                                                                                    } d-flex`}
                                                                            >
                                                                                {!/^(?=.*[A-Z])(?=.*[a-z]).*$/.test(
                                                                                    passwordStrength
                                                                                ) ? (
                                                                                    <span className="material-symbols-rounded filled-icon close-icon">
                                                                                        close
                                                                                    </span>
                                                                                ) : (
                                                                                    <span className="material-symbols-rounded filled-icon close-icon">
                                                                                        check
                                                                                    </span>
                                                                                )}
                                                                                An Uppercase &amp; Lowercase character
                                                                            </p>
                                                                        }
                                                                        {
                                                                            <p
                                                                                className={`${!/^(?=.*[.,:;\'!@#$%^&*_+=|(){}[?\-\]\/\\]).*$/.test(
                                                                                    passwordStrength
                                                                                )
                                                                                    ? "text-danger"
                                                                                    : "text-success"
                                                                                    } d-flex`}
                                                                            >
                                                                                {!/^(?=.*[.,:;\'!@#$%^&*_+=|(){}[?\-\]\/\\]).*$/.test(
                                                                                    passwordStrength
                                                                                ) ? (
                                                                                    <span className="material-symbols-rounded filled-icon close-icon">
                                                                                        close
                                                                                    </span>
                                                                                ) : (
                                                                                    <span className="material-symbols-rounded filled-icon close-icon">
                                                                                        check
                                                                                    </span>
                                                                                )}
                                                                                A special character
                                                                            </p>
                                                                        }
                                                                        {
                                                                            <p
                                                                                className={`${!/[0-9]/.test(passwordStrength)
                                                                                    ? "text-danger"
                                                                                    : "text-success"
                                                                                    } d-flex`}
                                                                            >
                                                                                {!/[0-9]/.test(passwordStrength) ? (
                                                                                    <span className="material-symbols-rounded filled-icon close-icon">
                                                                                        close
                                                                                    </span>
                                                                                ) : (
                                                                                    <span className="material-symbols-rounded filled-icon close-icon">
                                                                                        check
                                                                                    </span>
                                                                                )}
                                                                                A number
                                                                            </p>
                                                                        }
                                                                    </div>
                                                                ) : null}
                                                            </div>
                                                            <div className="mb-3 ">
                                                                <div className="psw-icon-section">
                                                                    <label for="" className="form-label">
                                                                        Re-Enter Password
                                                                    </label>
                                                                    <input
                                                                        type={password2 ? "text" : "password"}
                                                                        className="form-control"
                                                                        placeholder="example@mail.com"
                                                                        required
                                                                        aria-invalid={Boolean(
                                                                            registerValidation3?.re_password?.status ===
                                                                            false
                                                                        )}
                                                                        onChange={(e) => {
                                                                            setRegisterDetailsValue3(
                                                                                "re_password",
                                                                                e.target.value
                                                                            );
                                                                        }}
                                                                        onBlur={(e) => {
                                                                            setRegisterValidationValue3(
                                                                                "re_password",
                                                                                RePasswordValidation(
                                                                                    e.target.value,
                                                                                    registerDetail3?.password
                                                                                )
                                                                            );
                                                                        }}
                                                                    />
                                                                    <div className="psw-icon">
                                                                        {password2 ? (
                                                                            <span
                                                                                className="material-symbols-rounded cursor align-middle"
                                                                                onClick={() => viewPassword2()}
                                                                            >
                                                                                visibility
                                                                            </span>
                                                                        ) : (
                                                                            <span
                                                                                className="material-symbols-rounded cursor align-middle"
                                                                                onClick={() => viewPassword2()}
                                                                            >
                                                                                visibility_off
                                                                            </span>
                                                                        )}
                                                                    </div>
                                                                </div>

                                                                <small className="text-danger">
                                                                    {registerValidation3?.re_password?.message
                                                                        ? `Re-Password ${registerValidation3?.re_password?.message}`
                                                                        : ""}
                                                                </small>
                                                            </div>
                                                            <div className="mb-3">
                                                                <button
                                                                    type="button"
                                                                    className="btn  w-100 my-2  login-btn"
                                                                    onClick={(e) => {
                                                                        handleSubmitChangePassword(e);
                                                                    }}
                                                                >
                                                                    Change
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </CustomModal>
            </>
        </>
    );
};

export default Navigation;
