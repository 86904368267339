import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import MUIDataTable from "mui-datatables";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { ToastContainer, toast } from "react-toastify";
import { createAPI, createAPIV2, fetchWithNewQueryAPI, fetchWithQueryAPI } from "../../../Api/v1/Util/Api";
import { lead_service_v1_user_scheme_request_find_all_url, schemes_request_find_all_url } from "../../../Api/APIUrl";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { payment_service_url } from "../../../Api/BaseUrl";
import { showErrorMsg } from "../../../Store/util";
import { Windows } from "react-bootstrap-icons";

// Modal styles
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80%',
  maxWidth: '900px',
  height: '90vh',
  bgcolor: 'background.paper',
  boxShadow: 24,
  borderRadius: '10px',
  overflow: 'auto',
  p: 4,
};

export default function MyPlansRequestListComponent() {
  const [data, setData] = useState([]);
  const state = useSelector((state) => state);
  const navigate = useNavigate();
  const token = state.auth.auth.token;

  const fetchData = async () => {
    const response = await fetchWithNewQueryAPI(token, lead_service_v1_user_scheme_request_find_all_url + "?user_id=" + state?.auth?.auth?._id);
    console.log('response',response)
    if (response && response.statusCode === 200) {
      if (response.data) {
        setData(response.data.scheme_request);
      }
    }
  };

  useEffect(() => {
    fetchData();
  },[]);

  const userColumns = [
    {
      name: "scheme_title",
      label: "Scheme Title",
      options: {
        setCellProps: () => ({ style: { textAlign: 'center' } }),
        customBodyRender: (value, tableMeta) => (
          <span
            onClick={() => navigate(`/scheme-details/${value}/${data[tableMeta.rowIndex]._id}`)}
            style={{ color: "#007bff", textDecoration: "none", cursor: "pointer" }}
          >
            {value}
          </span>
        ),
      },
    },
    { 
      name: "monthly_amount", 
      label: "Monthly Amount", 
      options: { 
        setCellProps: () => ({ style: { textAlign: 'center' } }),
        customBodyRender: (value) => 
          new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR' }).format(value)
      } 
    },
    { 
      name: "gst_amount", 
      label: "GST Amount", 
      options: { 
        setCellProps: () => ({ style: { textAlign: 'center' } }),
        customBodyRender: (value) => 
          new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR' }).format(value)
      } 
    },
    
    // { name: "monthly_amount", label: "Monthly Amount", options: { setCellProps: () => ({ style: { textAlign: 'center' } }) } },
    // { name: "gst_amount", label: "GST Amount", options: { setCellProps: () => ({ style: { textAlign: 'center' } }) } },
    { name: "status", label: "Payment Status", options: { setCellProps: () => ({ style: { textAlign: 'center' } }) } },
    {
      name: "createdAt",
      label: "Created At",
      options: {
        setCellProps: () => ({ style: { textAlign: 'center' } }),
        customBodyRender: (value) => moment(value).format("DD/MM/YYYY, h:mm A"),
      },
    },
    {
      name: "final_payable_amount",
      label: "Amount Payable (Inclusive of GST)",
      options: {
        setCellProps: () => ({ style: { textAlign: 'center' } }),
        customBodyRender: (value, tableMeta) => {
          const itemId = data[tableMeta.rowIndex].payment_reference_id; // Get _id from data
          return (
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
              <button
                onClick={() => handleButtonClick(itemId)}
                style={{
                  marginLeft: "10px",
                  padding: "5px 10px",
                  backgroundColor: "#007bff",
                  color: "#fff",
                  border: "none",
                  borderRadius: "5px",
                  cursor: "pointer",
                }}
              >
                Pay Now {new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR' }).format(value)}
              </button>
            </div>
          );
        },
      },
    },
    // { name: "status", label: "Status", options: { setCellProps: () => ({ style: { textAlign: 'center' } }) } },
  ];
  
  const options = {
    selectableRows: "none",
    responsive: "standard",
    filter: true,
    search: true,
    pagination: false,
    tableBodyHeight: "500px",
    download: true,
    viewColumns: true,
    print: true,
    elevation: 1,
    rowsPerPage: 10,
    rowsPerPageOptions: [10],
    customBodyRender: value => <div style={{ textAlign: "center" }}>{value}</div>,
    customBodyRow: () => ({
      style: {
        borderBottom: "1px solid #ddd",
      },
    }),
  };

  const getMuiTheme = () =>
    createTheme({
      components: {
        MuiTableCell: {
          styleOverrides: {
            head: {
              whiteSpace: 'nowrap',
              textAlign: 'center',
              fontWeight: 'bold',
              backgroundColor: '#f5f5f5',
              fontSize: '14px',
            },
            body: {
              textAlign: 'center',
              fontSize: '14px',
            },
          },
        },
      },
    });

    const handleButtonClick = async (id) => {
      let payload = {
        reference_id:id,
        return_url:`https://dalmaf.com/paymentvalidation/plan-payment/${id}`
      }
      let formData = new FormData();
      for (const key in payload) {
        if (payload.hasOwnProperty(key)) {
          formData.append(key, payload[key]);
        }
      }
      let response = await createAPIV2(token,payment_service_url+"v1/scheme-request/payment/initiate",formData)
      // Add your logic here, e.g., API call, navigation, etc.
      if(response.statusCode===200){
        window.location.href = response?.data?.url

      }else{
        showErrorMsg('Failed to create payment link, Try Again')
      }
    };
    

  return (
    <div >
      <ToastContainer/>
      <ThemeProvider theme={getMuiTheme()}>
        <MUIDataTable
          title={`My Plans Request (${data?.length || 0})`}
          data={data.map(item => [
            item.scheme_title,
            item.monthly_amount || 0,
            item.gst_amount || 0,
            item.status === "pending" ? "Not Paid" : "Paid",
            // item.referred_by_first_name || "NA",
            item.createdAt || "NA",
            
            
            item.final_payable_amount || 0,
            item.payment_reference_id
            // statusOptions[item.status] || item.status,
          ])}
          columns={userColumns}
          options={options}
          style={{
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
            borderRadius: "10px",
            border: "1px solid #ddd",
          }}
        />
      </ThemeProvider>
      <ToastContainer />
    </div>
  );
}
