import React, { useEffect, useState } from "react";
import Logo from "../../Assets/Images/logo.webp";
import NewLog from "../../Assets/dalmaf-vertical-logo.png";
import { useNavigate } from "react-router-dom";
// import { Tag, TagGroup } from 'rsuite';
// import "rsuite/dist/rsuite.css";
import {
  ChangePassword,
  CreateOrder,
  CreatePaymentPackage,
  CreatePaymentService,
  CreatePaymentServiceCC,
  CreatePaymentServiceCCNL,
  CreatePaymentServiceWithoutToken,
  ForgotOtp,
  ForgotPassword,
  GetPackage,
  GetPackageAuto,
  GetUser,
  SignUp,
  UserLogin,
  UserServiceRequest,
  VerifyOtp,
  getAllPackagesUserAuto,
} from "../../Api/Api";
import { useDispatch, useSelector } from "react-redux";
import Norecord from "../../Assets/Images/MicrosoftTeams-image.webp";
import CustomModal from "../CustomModal";
import ManPowerIcon from "../../Assets/Images/si-1.webp";
import ManPowerNav from "../../Assets/Images/manpower-nav.webp";
import DropImgModal from "../../Assets/Images/navbar-img/drop-img-modal.webp";
import DropImgModal1 from "../../Assets/Images/navbar-img/navheadimg1.webp";
import DropImgModal2 from "../../Assets/Images/navbar-img/navheadimg2.webp";
import DropImgModal3 from "../../Assets/Images/navbar-img/navheadimg3.webp";
import DropImgModal4 from "../../Assets/Images/navbar-img/navheadimg4.webp";
import DropImgModal5 from "../../Assets/Images/navbar-img/navheadimg5.webp";
import androidImage from "../../Assets/Images/android-app-download.png";
import iosAppDownload from "../../Assets/Images/ios-app-download.png";
import android from "../../Assets/Images/android-app.png";
import iosDownload from "../../Assets/Images/ios-down-removebg.png";





import NavFam from "../../Assets/Images/navbar-img/fam.webp";
import DMLeft from "../../Assets/Images/dm-left.webp";
import A1 from "../../Assets/Images/navbar-img/a1.webp";
import A2 from "../../Assets/Images/navbar-img/a2.webp";
import A3 from "../../Assets/Images/navbar-img/a3.webp";
import A4 from "../../Assets/Images/navbar-img/a4.webp";
import A5 from "../../Assets/Images/navbar-img/a5.webp";
import OsNav from "../../Assets/Images/os-nav.webp";
import BuysellNav from "../../Assets/Images/buy-sell-nav.webp";
import AuctionNav from "../../Assets/Images/auction-nav.webp";
import LegalNav from "../../Assets/Images/legal-modal-nav.webp";
import LegalIcon from "../../Assets/Images/si-3.webp";
import MarketingIcon from "../../Assets/Images/marketing-icon-nav.webp";
import AuctionIcon from "../../Assets/Images/si-2.webp";
import BuySellIcon from "../../Assets/Images/buysell-icon.webp";
// import SplImg from "../../Assets/Images/special-offer-img.webp";
import SplImg from "../../Assets/Images/spl-off-img.webp";
import { ToastContainer, toast } from "react-toastify";
import {
  CreateNewsLetter,
  GetContent,
  GetLevel1,
  GetLevel2,
  GetMain,
} from "../../Api/Api";

import {
  EmailValidation,
  NonEmptyValidation,
  PasswordValidation,
  PhoneNumberValidation,
  RePasswordValidation,
  StringValidation,
} from "../../Store/validate";
import {
  loginSuccess,
  registerDetails,
  registerDetailsDelete,
} from "../../Store/auth/action";
import OtpInput from "react-otp-input";
import { url } from "../../Api/BaseUrl";
import { MoreOffers } from "../MoreOffers/more-offers";
import { LoginSignPass } from "../Basic/login-signup-forgot"
import { Box } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';

export default function Footer() {
  const navigate = useNavigate();

  const backToTop = () => {
    window.scrollTo(0, 0);
  };
  useEffect(() => {
    // getPackageList()
    getUserProfile();
    getauditContentList();
  }, []);

  const redirectPage = (routeName, id, category, subcategory, title) => {
    localStorage.setItem("level2Id", id);
    localStorage.setItem("category", category);
    localStorage.setItem("subcategory", subcategory);
    localStorage.setItem("title", title);
    navigate({
      pathname: "/Service",
      search: `tab=${routeName}&subtab=${category}|${subcategory}&id=${id}`,
    });
  };

  const [auctionContent, setAuctionContent] = useState(false);
  const [legalNavbarModal, setlegalNavbarModal] = useState(false);
  const [otherserviceNavbarModal, setotherserviceNavbarModal] = useState(false);
  const [otherserviceNavbarModal1, setotherserviceNavbarModal1] =
    useState(false);
  const [auditNavbarModal, setauditNavbarModal] = useState(false);
  const [manpowerNavbarModal, setmanpowerNavbarModal] = useState(false);
  const [selectedManPower, setSelectedManPower] = React.useState("Man Power");
  const [selectedLegal, setSelectedLegal] = React.useState("Civil Cases");
  const [selectedAudit, setSelectedAudit] = React.useState("Startup");
  const [selectedOS, setSelectedOS] = React.useState("Marketing");
  const [manPowerContent, setManPowerContent] = useState(false);
  const [auditContent, setAuditContent] = useState(false);
  const [BuySellContent, setBuySellContent] = useState(false);
  const [dropdownContent, setDropdownContent] = useState(false);
  const [OSContent, setOSContent] = useState(false);
  const [OSContent1, setOSContent1] = useState(false);
  const state = useSelector((state) => state);
  const [packageList, setPackageList] = useState([]);
  const [userProfile, setUserProfile] = useState([]);
  const getUserProfile = async () => {
    const id = state?.auth?.auth?._id;
    const token = state?.auth?.auth?.token;
    const response = await GetUser(token, id);
    if (response.statusCode === 200) {
      setUserProfile(response.data);
    } else {
      //console.log("error while getting user profile info");
    }
  };

  const getauditContentList = async () => {
    // const token = state.auth.auth.token;
    const response = await getAllPackagesUserAuto();
    if (response.statusCode === 200) {
      setPackageList(response.data.filter((e) => e.customPackage === "Auto"));
    } else {
      setPackageList([]);

      //console.log("error while getting user profile info");
    }
  };
  //error
  const showToastErrorMsg = (errMsg) => {
    toast.error(errMsg, {
      position: toast.POSITION.TOP_RIGHT,
      className: "toast-message",
      autoClose: 5000,
    });
  };

  //success
  const showToastSuccessMsg = (successMsg) => {
    toast.success(successMsg, {
      position: toast.POSITION.TOP_RIGHT,
      className: "toast-message",
      autoClose: 3000,
    });
  };

  let data = [
    {
      name: "Apartment",
      route: "/OtherService&tab=Apartment",
      icon: MarketingIcon,
      type: "Auction",
    },
    {
      name: "Independent Villa",
      route: "/OtherService&tab=Villa",
      icon: MarketingIcon,
      type: "Auction",
    },
    {
      name: "Individual House",
      route: "/OtherService&tab=IndividualHouse",
      icon: MarketingIcon,
      type: "Auction",
    },
    {
      name: "Plot",
      route: "/OtherService&tab=Plot",
      icon: MarketingIcon,
      type: "Auction",
    },
    {
      name: "Gold",
      route: "/OtherService&tab=Gold",
      icon: MarketingIcon,
      type: "Auction",
    },
    {
      name: "Two Wheeler",
      route: "/OtherService&tab=TwoWheeler",
      icon: MarketingIcon,
      type: "Auction",
    },
    {
      name: "Four Wheeler",
      route: "/OtherService&tab=FourWheeler",
      icon: MarketingIcon,
      type: "Auction",
    },
  ];

  let data1 = [
    {
      name: "Apartment",
      route: "/OtherService&tab=BSApartment",
      icon: BuySellIcon,
      type: "Buy/Sell",
    },
    {
      name: "Independent Villa",
      route: "/OtherService&tab=BSVilla",
      icon: BuySellIcon,
      type: "Buy/Sell",
    },
    {
      name: "Individual House",
      route: "/OtherService&tab=BSIndividualHouse",
      icon: BuySellIcon,
      type: "Buy/Sell",
    },
    {
      name: "Plot",
      route: "/OtherService&tab=BSPlot",
      icon: BuySellIcon,
      type: "Buy/Sell",
    },
    {
      name: "Gold",
      route: "/OtherService&tab=BSGold",
      icon: BuySellIcon,
      type: "Buy/Sell",
    },
    {
      name: "Two Wheeler",
      route: "/OtherService&tab=BSTwoWheeler",
      icon: BuySellIcon,
      type: "Buy/Sell",
    },
    {
      name: "Four Wheeler",
      route: "/OtherService&tab=BSFourWheeler",
      icon: BuySellIcon,
      type: "Buy/Sell",
    },
  ];

  const [BookNow, setBookNow] = useState(false);
  let cat = localStorage?.getItem("category")?.split("?");
  const handleOrder = async (e, amount, type, id, Logintype) => {
    handleCCAvenuePaymentNL(localStorage?.getItem("LPrice"), localStorage?.getItem("LId"), false, serviceReq1.name, serviceReq1.mobileNumber, serviceReq1.email);
  };

  const [loginModal, setloginModal] = useState(false);
  const [BookNowForm, setBookNowForm] = useState(false);
  const [createModal, setcreateModal] = useState(false);
  const [fpwModal, setfpwModal] = useState(false);
  const [otpModal, setotpModal] = useState(false);
  const [otpFgtModal, setotpFgtModal] = useState(false);
  const [changepswModal, setchangepswModal] = useState(false);

  const [password, setPassword] = useState();
  const viewPassword = () => {
    setPassword(!password);
  };

  const [passwordStrength, setPasswordStrength] = useState("");
  const [registerDetail, setRegisterDetails] = useState({});

  const [registerValidation, setRegisterValidation] = useState({});

  const setRegisterDetailsValue = (key, value) => {
    setRegisterDetails({ ...registerDetail, [key]: value });
    if (registerValidation[key]) delete registerValidation[key];
  };

  const setRegisterValidationValue = (key, value) => {
    setRegisterValidation({ ...registerValidation, [key]: value });
  };

  const dispatch = useDispatch();
  // const location = useLocation();
  //error

  //User Registration api call

  const handleSubmit = async (e) => {
    e.preventDefault();
    let validate = {};
    validate.name = StringValidation(registerDetail?.name);
    validate.email = EmailValidation(registerDetail?.email?.toLowerCase());
    validate.password = PasswordValidation(registerDetail?.password);
    validate.phoneNumber = PhoneNumberValidation(registerDetail?.phoneNumber);
    setRegisterValidation(validate);
    if (Object.values(validate).every((v) => v.status === true)) {
      const Payload = {
        name: registerDetail.name,
        email: registerDetail.email?.toLowerCase(),
        password: registerDetail.password,
        phoneNumber: registerDetail.phoneNumber,
        role: "user",
      };

      const response = await SignUp(Payload);
      if (response && response.statusCode === 200) {
        showToastSuccessMsg(response.message);
        setcreateModal(false);
        setRegisterDetails({});
        setRegisterValidationValue({});
        setotpModal(true);
        dispatch(registerDetails(registerDetail));
      } else {
        showToastErrorMsg(
          response.message || response.error.response.data.message
        );
        //setfailureModal(true);
      }
    } else {
      //console.log("Error while register as user");
    }
  };

  const handleSubmitResend = async (e) => {
    e.preventDefault();
    const Payload = {
      name: state.auth.registerData.name,
      email: state.auth.registerData.email,
      password: state.auth.registerData.password,
      phoneNumber: state.auth.registerData.phoneNumber,
      role: "user",
    };

    const response = await SignUp(Payload);
    if (response && response.statusCode === 200) {
      showToastSuccessMsg(response.message);
      setMinutes(0);
      setSeconds(59);
    } else {
      showToastErrorMsg(
        response.message || response.error.response.data.message
      );
      //setfailureModal(true);
    }
  };

  const [code, setOtp] = useState("");
  const [validation, setValidation] = useState({});
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);

  const [moreOffers, setMoreOffers] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(interval);
        } else {
          setSeconds(59);
          setMinutes(minutes - 1);
        }
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  });
  //Otp Submit
  const handleChange = (code) => {
    setOtp(code);
    setValidation({});
  };

  // otp
  const handleSubmitOtp = async (data, value) => {
    data.preventDefault();
    let validate = {};
    validate.code = NonEmptyValidation(code);
    setValidation(validate);
    if (Object.values(validate).every((v) => v.status === true)) {
      const Payload = {
        email: state.auth.registerData.email,
        otp: parseInt(code),
      };
      let response = await VerifyOtp(Payload);
      if (response && response.statusCode === 200) {
        setotpModal(false);
        setOtp("");
        setloginModal(true);
        showToastSuccessMsg(response.message);
      } else {
        showToastErrorMsg(
          response.message || response.error.response.data.message
        );
        // setFailureModal(true);
      }
    } else {
      //console.log("Error while verify otp");
    }
  };
  const [openTag, setOpenTag] = useState(true);
  const handleSubmitOtpFgt = async (data) => {
    data.preventDefault();
    let validate = {};
    validate.code = NonEmptyValidation(code);
    setValidation(validate);
    if (Object.values(validate).every((v) => v.status === true)) {
      const Payload = {
        email: state.auth.registerData.email,
        otp: parseInt(code),
      };
      let response = await ForgotOtp(Payload);
      if (response && response.statusCode === 200) {
        showToastSuccessMsg(response.message);
        setotpFgtModal(false);
        setchangepswModal(true);
        setOtp("");
      } else {
        showToastErrorMsg(
          response.message || response.error.response.data.message
        );
        // setFailureModal(true);
      }
    }
  };
  const [registerDetail1, setRegisterDetails1] = useState({});

  const [registerValidation1, setRegisterValidation1] = useState({});

  const setRegisterDetailsValue1 = (key, value) => {
    setRegisterDetails1({ ...registerDetail1, [key]: value });
    if (registerValidation1[key]) delete registerValidation1[key];
  };

  const setRegisterValidationValue1 = (key, value) => {
    setRegisterValidation1({ ...registerValidation1, [key]: value });
  };

  const handleSubmit1 = async (e) => {
    e.preventDefault();
    let validate = {};
    validate.email = EmailValidation(registerDetail1?.email?.toLowerCase());
    validate.password = PasswordValidation(registerDetail1?.password);
    setRegisterValidation1(validate);
    if (Object.values(validate).every((v) => v.status === true)) {
      const Payload = {
        email: registerDetail1.email?.toLowerCase(),
        password: registerDetail1.password,
      };

      const response = await UserLogin(Payload);
      if (response && response.statusCode === 200) {
        dispatch(loginSuccess(response.data, response?.data?.token));
        if (localStorage.getItem("tab")) {
          navigate(`/${localStorage.getItem("tab")}`);
          setloginModal(false);
        } else {
          setloginModal(false);
          navigate("/");
        }

        setRegisterDetails1({});
      } else {
        showToastErrorMsg(
          response.message || response.error.response.data.message
        );
        //setfailureModal(true);
      }
    } else {
      //console.log("Error while login as user");
    }
  };

  const [registerDetail2, setRegisterDetails2] = useState({});

  const [registerValidation2, setRegisterValidation2] = useState({});

  const setRegisterDetailsValue2 = (key, value) => {
    setRegisterDetails2({ ...registerDetail2, [key]: value });
    if (registerValidation2[key]) delete registerValidation2[key];
  };

  const setRegisterValidationValue2 = (key, value) => {
    setRegisterValidation2({ ...registerValidation2, [key]: value });
  };

  //forgot password api call

  const handleSubmitForgotPassword = async (e) => {
    e.preventDefault();
    let validate = {};
    validate.email = EmailValidation(registerDetail2?.email?.toLowerCase());
    setRegisterValidation2(validate);
    if (Object.values(validate).every((v) => v.status === true)) {
      const Payload = {
        email: registerDetail2.email?.toLowerCase(),
      };

      const response = await ForgotPassword(Payload);
      if (response && response.statusCode === 200) {
        dispatch(registerDetailsDelete(registerDetail2));
        showToastSuccessMsg(response.message);
        setfpwModal(false);
        setotpFgtModal(true);
        setRegisterDetails2({});
      } else {
        showToastErrorMsg(
          response.message || response.error.response.data.message
        );
        //setfailureModal(true);
      }
    } else {
      //console.log("Error while reset password");
    }
  };

  const [registerDetail3, setRegisterDetails3] = useState({});

  const [registerValidation3, setRegisterValidation3] = useState({});

  const setRegisterDetailsValue3 = (key, value) => {
    setRegisterDetails3({ ...registerDetail3, [key]: value });
    if (registerValidation3[key]) delete registerValidation3[key];
  };

  const setRegisterValidationValue3 = (key, value) => {
    setRegisterValidation3({ ...registerValidation3, [key]: value });
  };

  //forgot password api call

  const handleSubmitChangePassword = async (e) => {
    e.preventDefault();
    let validate = {};
    validate.password = PasswordValidation(registerDetail3?.password);
    validate.re_password = RePasswordValidation(
      registerDetail3?.re_password,
      registerDetail3?.password
    );
    setRegisterValidation3(validate);
    if (Object.values(validate).every((v) => v.status === true)) {
      const Payload = {
        email: state.auth.registerData.email,
        password: registerDetail3?.password,
      };

      const response = await ChangePassword(Payload);
      if (response && response.statusCode === 200) {
        showToastSuccessMsg(response.message);
        setchangepswModal(false);
        setloginModal(true);
        setRegisterDetails3({});
        getUserProfile();
      } else {
        showToastErrorMsg(
          response.message || response.error.response.data.message
        );
        //setfailureModal(true);
      }
    } else {
      //console.log("Error while chenge password");
    }
  };

  const [serviceReq1, setServiceReq1] = useState({});
  const [form, setForm] = useState(false);

  const [serviceReqValidation1, setServiceReqValidation1] = useState({});

  const setServiceReqValue1 = (key, value) => {
    setServiceReq1({ ...serviceReq1, [key]: value });
    if (serviceReqValidation1[key]) delete serviceReqValidation1[key];
  };

  const setServiceReqValidationValue1 = (key, value) => {
    setServiceReqValidation1({ ...serviceReqValidation1, [key]: value });
  };

  const handleSubmitServiceform = async (e) => {
    e.preventDefault();
    const Payload = {
      name: serviceReq1.name,
      email: serviceReq1.email,
      subject: serviceReq1.subject,
      question: serviceReq1.question,
      mobileNumber: serviceReq1.mobileNumber,
      type: "service",
      servicesCategory: cat[0].substring(cat[0].lastIndexOf("/") + 1),
      servicesSubcategory: localStorage?.getItem("subcategory"),
      servicesTitle: localStorage?.getItem("title"),
    };

    const response = await UserServiceRequest(Payload);
    if (response && response.statusCode === 200) {
      showToastSuccessMsg(response.message);
      setServiceReq1({});
      setForm(false);
    } else {
      showToastErrorMsg(
        response.message || response.error.response.data.message
      );
    }
  };

  const [getMyTaskMain, setGetMyTaskMain] = useState([]);
  const [getMyTasklevel1, setGetMyTasklevel1] = useState([]);
  const [getMyTasklevel2, setGetMyTasklevel2] = useState([]);

  const GetMyTsksMain = async () => {
    // const token = state.auth.auth.token;
    let data = await GetMain("active");
    if (data && data.statusCode === 200) {
      const numAscending = [...data.data].sort(
        (a, b) => b.displayOrder < a.displayOrder
      );
      setGetMyTaskMain(numAscending);
    } else {
      setGetMyTaskMain([]);
      //console.log("Error while getting my task");
    }
  };
  //console.log(getMyTaskMain);
  const GetMyTsksLevel1 = async (status, id) => {
    // const token = state.auth.auth.token;
    let data = await GetLevel1(status, id);
    if (data && data.statusCode === 200) {
      const numAscending = [...data.data].sort(
        (a, b) => b.displayOrder < a.displayOrder
      );
      setGetMyTasklevel1(numAscending);
    } else {
      setGetMyTasklevel1([]);
      //console.log("Error while getting my task");
    }
  };

  const GetMyTsksLevel2 = async (status, id, id1) => {
    // const token = state.auth.auth.token;
    let data = await GetLevel2(status, id, id1);
    if (data && data.statusCode === 200) {
      const numAscending = [...data.data].sort(
        (a, b) => b.displayOrder < a.displayOrder
      );
      setGetMyTasklevel2(numAscending);
    } else {
      setGetMyTasklevel2([]);
      //console.log("Error while getting my task");
    }
  };

  useEffect(() => {
    GetMyTsksMain();
  }, []);

  //CCAveune Payment Gateway Details

  const redirectURL = (url) => {
    window.open(url, "", "noreferrer");
    // window.location.replace(url);
  };

  const generateOrderId = () => {
    const today = new Date();
    const currentYear = 'DALMaf-PKG-' + today.getFullYear() + "/" + (today.getMonth() + 1) + "/" + today.getDate() + "/" + today.getHours() + today.getMinutes() + today.getMilliseconds();
    return currentYear;
  };

  const handleCCAvenuePayment = async (amount, id, isLoggedIn) => {
    const orderId = generateOrderId();
    const Payload = {
      orderId: orderId,
      purchaseType: "package",
      packagePurchaseId: id,
      price: amount,
      paymentInfo: "",
      status: "Not Paid",
      is_logedin: isLoggedIn,
    };
    localStorage?.setItem(
      "url",
      window.location.url
    );
    const token = state?.auth?.auth?.token;
    const response = await CreatePaymentServiceCC(Payload, token);
    if (response && response.statusCode === 200) {
      redirectURL(response.data.url)
    } else {
      showToastErrorMsg(
        response.message || response.error.response.data.message
      );
    }
  };

  const handleCCAvenuePaymentNL = async (amount, id, isLoggedIn, name, mobileNumber, email) => {
    const orderId = generateOrderId();
    const Payload = {
      orderId: orderId,
      purchaseType: "package",
      packagePurchaseId: id,
      price: amount,
      paymentInfo: "",
      status: "Not Paid",
      is_logedin: isLoggedIn,
      name: name,
      mobileNumber: mobileNumber,
      email: email,
    };
    const token = state?.auth?.auth?.token;
    const response = await CreatePaymentServiceCCNL(Payload, token);
    if (response && response.statusCode === 200) {
      redirectURL(response.data.url)
    } else {
      showToastErrorMsg(
        response.message || response.error.response.data.message
      );
    }
  };
  const style = {
    position: 'relative',
    top: '50%',
    left: '50%',
    transform: 'translateX(-50%)',
    width: '90%',
    maxWidth: "1000px",
  };
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  // useEffect(() => {
  //   const getCookieValue = (name) => (
  //     document.cookie.match('(^|;)\\s*' + name + '\\s*=\\s*([^;]+)')?.pop() || ''
  //   )
  //   if (getCookieValue('open-popup') === true || getCookieValue('open-popup') == 'true') {
  //     setOpen(false)
  //     return
  //   }
  //   setOpen(true)
  // }, [])

  return (
    <div>
      <ToastContainer />
      {/* <CustomModal
        open={open}
        onClickOutside={() => {
          document.cookie = "open-popup=true; max-age=600; path=/;";
          setOpen(false);

        }}
      >
        <span onClick={() => {
          setOpen(false);
          document.cookie = "open-popup=true; max-age=600; path=/;";
          navigate("/partner-with-dalmaf")
        }}>
          <Box sx={style} className="popup-banner" >
            <div onClick={() => {
              document.cookie = "open-popup=true; max-age=600; path=/;";
              setOpen(false);
            }} className="text-dark close-icon-btn" style={{
              position: 'absolute',
            }}>
              <CloseIcon />
            </div>
          </Box>
        </span>


      </CustomModal> */}
      <footer class="footer-wrapper footer-layout2" style={{zIndex:'-1'}}>
        <div class="widget-area">
          <div class="container">
            <div class="row justify-content-between">
              <div class="col-md-6 col-xxl-3 col-xl-4">
                <div class="widget footer-widget">
                  <div class="th-widget-about">
                    <div class="about-logo">
                      <a href="" onClick={(e) => { e.preventDefault(); window.scrollTo(0, 0); navigate('/') }}><img src={NewLog} alt="Webteck" /></a>
                    </div>
                    <p class="about-text" style={{ color: 'black' }}>
                      At DALMAF, we deliver sustainable outcomes and solutions for your business needs through our tech-enabled and purposeful services. Our offerings span audit & assurance, advisory, consulting, and regulatory domains, ensuring your business operates compliantly and cohesively.
                    </p>
                    <a href="https://play.google.com/store/apps/details?id=com.dalmaf" target="_blank">
                      <img src={androidImage} alt="Download Dalmaf app now" width="50%" />
                    </a>
                    <a href="https://apps.apple.com/in/app/dalmaf/id6467872452" target="_blank">
                      <img src={iosAppDownload} alt="Download Dalmaf app now" width="50%" />
                    </a>
                    {/* <div class="th-social">
                      
                      <a href="https://www.facebook.com/"><i
                      class="fab fa-facebook-f"></i></a> <a href="https://www.twitter.com/"><i
                        class="fab fa-twitter"></i></a> <a href="https://www.linkedin.com/"><i
                          class="fab fa-linkedin-in"></i></a> <a href="https://www.whatsapp.com/"><i
                            class="fab fa-whatsapp"></i></a>
                    </div> */}
                  </div>
                </div>
              </div>
              <div class="col-md-6 col-xl-auto">
                <div class="widget widget_nav_menu footer-widget">
                  <h3 class="widget_title">Quick Links</h3>
                  <div class="menu-all-pages-container">
                    <ul class="menu">
                      <li onClick={() => redirectPage("Divorce", "64883fcd0efe11b73d720fca", "Legal", "Family Cases", "Divorce in Different Religion")}>
                        <a href="#">Divorce</a>
                      </li>
                      <li onClick={() => redirectPage("Domestic Violation", "64883f930efe110303720fbe", "Legal", "Family Cases", "Domestic Violation")}>
                        <a href="#">Domestic Violation</a>
                      </li>
                      <li onClick={() => redirectPage("Child Custody", "648843610efe11b236721112", "Legal", "Family Cases", "Guardian & Child Custody Cases")}>
                        <a href="#">Child Custody</a>
                      </li>
                      <li onClick={() => redirectPage("Civil Suits", "648835d20efe11493d72073f", "Legal", "Civil Cases", "Civil Suits")}>
                        <a href="#">Civil Suits</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="col-md-6 col-xl-auto">
                <div class="widget widget_nav_menu footer-widget">
                  <h3 class="widget_title">Quick Links</h3>
                  <div class="menu-all-pages-container">
                    <ul class="menu" style={{ color: 'black' }}>
                      <li onClick={() => redirectPage("Flex Staffing", "648832320efe1142657203af", "Man Power", "Flexi Staffing")}>
                        <a href="#">Flexi Staffing</a>
                      </li>
                      <li onClick={() => redirectPage("Contract Employee", "648833590efe115a95720485", "Man Power", "Contract Employee")}>
                        <a href="#">Contract Employee</a>
                      </li>
                      <li onClick={() => redirectPage("Backoffice Staffing", "648834ec0efe116a247206de", "Man Power", "Back Office Staffing")}>
                        <a href="#">Backoffice Staffing</a>
                      </li>
                      <li onClick={() => redirectPage("Permanent Staffing", "6488352c0efe11d1aa7206ef", "Man Power", "Permanent Staffing")}>
                        <a href="#">Permanent Staffing</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="col-md-6 col-xl-auto">
                <div class="widget footer-widget">
                  <h3 class="widget_title">Contact Us</h3>
                  <div class="th-widget-contact">
                    <div class="contact-feature">
                      <div class="icon-btn"><i class="fa-solid fa-phone"></i></div>
                      <div class="media-body">
                        <p class="contact-feature_label">Phone Number</p><a href="tel:+919884211885"
                          class="contact-feature_link">+91 9884211885</a>
                      </div>
                    </div>
                    <div class="contact-feature">
                      <div class="icon-btn"><i class="fa-solid fa-envelope"></i></div>
                      <div class="media-body">
                        <p class="contact-feature_label">Email address</p><a
                          href="mailto:info@dalmaf.com"
                          class="contact-feature_link">info@dalmaf.com</a>
                      </div>
                    </div>
                    <div class="contact-feature">
                      <div class="icon-btn"><i class="fa-solid fa-location-dot"></i></div>
                      <div class="media-body">
                        <p class="contact-feature_label">office location</p><a
                          href="https://www.bing.com/search?FORM=U523DF&PC=U523&q=Dalmaf+Private+Limited%2C+No.+56%2F44%2C+A2-Block%2C+2nd+Floor%2C+Thirumalai+Pillai+Road%2C+T.+Nagar%2C+Chennai-600+017" class="contact-feature_link">
                          {/* No 56, 4, Thirumalai Pillai Road, <br /> T Nagar, Chennai, Chennai, Tamil Nadu, 600017</a> */}
                          T Nagar, Chennai, Chennai, Tamil Nadu, 600017</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="copyright-wrap bg-theme">
          <div class="container">
            <div class="row justify-content-between align-items-center">
              <div class="col-lg-6">
                <p class="copyright-text text-white">Copyright <i class="fal fa-copyright"></i> &copy; 2024 DALMAF Private Limited. All rights reserved</p>
                {/* <a
                  href="https://themeforest.net/user/themeholy">Themeholy</a>. All Rights Reserved.</p> */}
              </div>
              <div class="col-lg-6 text-end d-none d-lg-block">
                <div class="footer-links">
                  <ul>
                    <li><a href="" onClick={(e) => { e.preventDefault(); navigate("/TermsandCondition") }} >Terms & Condition</a></li>
                    <li><a href="" onClick={(e) => { e.preventDefault(); navigate("/Career") }} >Careers</a></li>
                    <li><a href="" onClick={(e) => { e.preventDefault(); navigate("/Privacy-Policy") }} >Privacy Policy</a></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
      {/* <div className="footer pt-5 pb-5">
        <span onClick={backToTop} className="float">
          <i className="fa fa-chevron-up my-float"></i>
        </span>
        <div className="container">
          <div className="row">
            <div className="col-lg-1 col-md-3 col-xs-12 links">
              <img className="footer-logo-img" src={Logo} alt="Dalmaf Logo" />
            </div>
            <div className="col-lg-2 col-md-3 col-xs-12 links">
              <h4 className="mt-lg-0 mt-sm-3 f-heading">Quick Links</h4>
              <ul className="m-0 p-0">
                <li onClick={() => navigate("/")}>
                  <a href="#">Home</a>
                </li>
                <li onClick={() => navigate("/About")}>
                  <a href="#">About Us</a>
                </li>
                <li onClick={() => navigate("/Career")}>
                  <a href="#">Career</a>
                </li>
                <li onClick={() => navigate("/Contact")}>
                  <a href="#">Contact Us</a>
                </li>
                <li onClick={() => navigate("/Privacy-Policy")}>
                  <a href="#">Privacy Policy</a>
                </li>
                <li onClick={() => navigate("/TermsandCondition")}>
                  <a href="#">Terms and Condition</a>
                </li>
                <li onClick={() => navigate("/cancellation-and-refund-policy")}>
                  <a href="#">Cancellation and Refund Policy</a>
                </li>
              </ul>
            </div>
            <div className="col-lg-2 col-md-3 col-xs-12 links">
              <h4 className="mt-lg-0 mt-sm-3 f-heading">Legal</h4>
              <ul className="m-0 p-0">
                <li onClick={() => redirectPage("Divorce", "64883fcd0efe11b73d720fca", "Legal", "Family Cases", "Divorce in Different Religion")}>
                  <a href="#">Divorce</a>
                </li>
                <li onClick={() => redirectPage("Domestic Violation", "64883f930efe110303720fbe", "Legal", "Family Cases", "Domestic Violation")}>
                  <a href="#">Domestic Violation</a>
                </li>
                <li onClick={() => redirectPage("Child Custody", "648843610efe11b236721112", "Legal", "Family Cases", "Guardian & Child Custody Cases")}>
                  <a href="#">Child Custody</a>
                </li>
                <li onClick={() => redirectPage("Civil Suits", "648835d20efe11493d72073f", "Legal", "Civil Cases", "Civil Suits")}>
                  <a href="#">Civil Suits</a>
                </li>
              </ul>
            </div>
            <div className="col-lg-2 col-md-3 col-xs-12 links">
              <h4 className="mt-lg-0 mt-sm-3 f-heading">Audit</h4>
              <ul className="m-0 p-0">
                <li onClick={() => redirectPage("Start Up", "6488081d0efe117f0971eb24", "Audit", "Startup", "Partnership Registration")}>
                  <a href="#">Start Up</a>
                </li>
                <li onClick={() => redirectPage("GST", "64882f700efe113f95720085", "Audit", "GST", "GST Registration")}>
                  <a href="#">GST</a>
                </li>
                <li onClick={() => redirectPage("Trade Mark", "64882ce80efe11775471ffc7", "Audit", "Trademark", "Trademark Registration For Individuals")}>
                  <a href="#">Trade Mark</a>
                </li>
                <li onClick={() => redirectPage("Income Tax", "648834790efe110d16720614", "Audit", "Income Tax", "Tax Notice")}>
                  <a href="#">Income Tax</a>
                </li>
              </ul>
            </div>
            <div className="col-lg-2 col-md-3 col-xs-12 links">
              <h4 className="mt-lg-0 mt-sm-3 f-heading">Man Power</h4>
              <ul className="m-0 p-0">
                <li onClick={() => redirectPage("Flex Staffing", "648832320efe1142657203af", "Man Power", "Flexi Staffing")}>
                  <a href="#">Flexi Staffing</a>
                </li>
                <li onClick={() => redirectPage("Contract Employee", "648833590efe115a95720485", "Man Power", "Contract Employee")}>
                  <a href="#">Contract Employee</a>
                </li>
                <li onClick={() => redirectPage("Backoffice Staffing", "648834ec0efe116a247206de", "Man Power", "Back Office Staffing")}>
                  <a href="#">Backoffice Staffing</a>
                </li>
                <li onClick={() => redirectPage("Permanent Staffing", "6488352c0efe11d1aa7206ef", "Man Power", "Permanent Staffing")}>
                  <a href="#">Permanent Staffing</a>
                </li>
              </ul>
            </div>
            <div className="col-lg-3 col-md-8 col-xs-12 location">
              <h4 className="mt-lg-0 mt-sm-3 f-heading">How to Contact Us?</h4>
              <p className="mb-2">
                <span className="material-symbols-rounded align-middle me-1 filled-icon">phone_iphone</span>
                <a className="text-dark text-decoration-none" href="tel:+91 9884211885" target="_blank">
                  +91 9884211885
                </a>
              </p>
              <p className="mb-2">
                <span className="material-symbols-rounded align-middle me-1 filled-icon">mail</span>
                <a className="text-dark text-decoration-none" href="mailto:info@dalmaf.com" target="_blank">
                  info@dalmaf.com
                </a>
              </p>
              <p className="mb-0">
                <span className="material-symbols-rounded align-middle me-1 filled-icon">location_on</span>
                <a className="text-dark text-decoration-none" href="https://www.bing.com/search?FORM=U523DF&PC=U523&q=Dalmaf+Private+Limited%2C+No.+56%2F44%2C+A2-Block%2C+2nd+Floor%2C+Thirumalai+Pillai+Road%2C+T.+Nagar%2C+Chennai-600+017" target="_blank">
                  No 56, 4, Thirumalai Pillai Road, <br /> T Nagar, Chennai, Chennai, Tamil Nadu, 600017
                </a>
              </p>
              <a href="https://play.google.com/store/apps/details?id=com.dalmaf" target="_blank">
                <img src={androidImage} alt="Download Dalmaf app now" width="50%" />
              </a>
              <a href="https://apps.apple.com/in/app/dalmaf/id6467872452" target="_blank">
                <img src={iosAppDownload} alt="Download Dalmaf app now" width="50%" />
              </a>
            </div>
          </div>
        </div>
      </div>

      <div className="text-center">&copy; 2024 DALMAF Private Limited. All rights reserved</div> */}


      <MoreOffers
        open={moreOffers}
        onClickOutside={() => {
          setMoreOffers(false);
        }}
      />

      <LoginSignPass open={loginModal}
        onClickOutside={() => {
          setloginModal(false);
        }} />

      <CustomModal
        open={form}
        onClickOutside={() => {
          setForm(false);
        }}
      >
        <div className="modal-content w-50 m-auto">
          <div className="modal-body p-0 ">
            <div className="container ">
              <span
                className="material-symbols-rounded filled-icon close-icon"
                style={{
                  marginRight: "30px",
                  marginTop: "50px",
                  textShadow: "rgb(171 167 167 / 44%) 0px 3px 6px",
                }}
                onClick={() => {
                  setForm(false);
                }}
              >
                close
              </span>
              <div className="row d-flex align-items-center justify-content-center  mt-0">
                <div className="container ">
                  <div className="row justify-content-center">
                    <div className="col-lg-12">
                      <div className=" my-5 px-3">
                        <div className="row justify-content-center mt-2">
                          <div className="mb-2">
                            <b>
                              Please enter your information below, <br />
                              Our staff will contact you as soon as possible.
                            </b>
                          </div>
                          <hr />
                          <div className="col-lg-6 col-md-6">
                            <div className="mb-4">
                              <label className="form-label">
                                Name <span className="text-danger">*</span>
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Enter Your Name"
                                aria-invalid={Boolean(
                                  serviceReqValidation1?.name?.status ===
                                  false
                                )}
                                defaultValue={serviceReq1?.name}
                                onChange={(e) => {
                                  setServiceReqValue1("name", e.target.value);
                                }}
                                onBlur={(e) => {
                                  setServiceReqValidationValue1(
                                    "name",
                                    StringValidation(e.target.value)
                                  );
                                }}
                              />
                              <small className="text-danger">
                                {serviceReqValidation1?.name?.message
                                  ? `Name ${serviceReqValidation1?.name?.message}`
                                  : ""}
                              </small>
                            </div>
                          </div>
                          <div className="col-lg-6 col-md-6">
                            <div className="mb-4">
                              <label className="form-label">
                                Mobile No{" "}
                                <span className="text-danger">*</span>
                              </label>
                              <input
                                type="tel"
                                maxLength={10}
                                className="form-control"
                                defaultValue={serviceReq1?.mobileNumber}
                                placeholder="Enter Your Mobile No"
                                aria-invalid={Boolean(
                                  serviceReqValidation1?.mobileNumber
                                    ?.status === false
                                )}
                                onChange={(e) => {
                                  setServiceReqValue1(
                                    "mobileNumber",
                                    e.target.value
                                  );
                                }}
                                onBlur={(e) => {
                                  setServiceReqValidationValue1(
                                    "mobileNumber",
                                    PhoneNumberValidation(e.target.value)
                                  );
                                }}
                              />
                              <small className="text-danger">
                                {serviceReqValidation1?.mobileNumber?.message
                                  ? `Mobile no ${serviceReqValidation1?.mobileNumber?.message}`
                                  : ""}
                              </small>
                            </div>
                          </div>
                          <div className="col-lg-6 col-md-6">
                            <div className="mb-4">
                              <label className="form-label">
                                E- Mail ID{" "}
                                <span className="text-danger">*</span>
                              </label>
                              <input
                                type="email"
                                className="form-control"
                                placeholder="Enter Your E- Mail ID"
                                aria-invalid={Boolean(
                                  serviceReqValidation1?.email?.status ===
                                  false
                                )}
                                defaultValue={
                                  serviceReq1?.email?.toLowerCase() || ""
                                }
                                onChange={(e) => {
                                  setServiceReqValue1(
                                    "email",
                                    e.target.value?.toLowerCase()
                                  );
                                }}
                                onBlur={(e) => {
                                  setServiceReqValidationValue1(
                                    "emailId",
                                    EmailValidation(
                                      e.target.value
                                    )?.toLowerCase()
                                  );
                                }}
                              />
                              <small className="text-danger">
                                {serviceReqValidation1?.email?.message
                                  ? `Email ${serviceReqValidation1?.email?.message}`
                                  : ""}
                              </small>
                            </div>
                          </div>
                          <div className="col-lg-6 col-md-6">
                            <div className="mb-4">
                              <label className="form-label">Questions</label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Enter Your Questions "
                                aria-invalid={Boolean(
                                  serviceReqValidation1?.question?.status ===
                                  false
                                )}
                                onChange={(e) => {
                                  setServiceReqValue1(
                                    "question",
                                    e.target.value
                                  );
                                }}
                              />
                            </div>
                          </div>

                          <div className="col-lg-12">
                            <div className="mb-4">
                              <label className="form-label">
                                Service Type{" "}
                                <span className="text-danger">*</span>
                              </label>
                              <select
                                className="form-control form-control-lg"
                                value={serviceReq1?.servicesType}
                                onChange={(e) => {
                                  setServiceReqValue1(
                                    "servicesType",
                                    e.target.value
                                  );
                                }}
                                onBlur={(e) => {
                                  setServiceReqValidationValue1(
                                    "servicesType",
                                    NonEmptyValidation(e.target.value)
                                  );
                                }}
                              >
                                <option value="">
                                  ---Select Service Type---
                                </option>
                                <option value="Legal">Legal</option>
                                <option value="Audit">Audit</option>
                                <option value="Manpower">Manpower</option>
                                <option value="Auction">Auction</option>
                                <option value="Buy/Sell">Buy/Sell</option>
                              </select>
                              <small className="text-danger">
                                {serviceReqValidation1?.servicesType?.message
                                  ? `Service Type ${serviceReqValidation1?.servicesType?.message}`
                                  : ""}
                              </small>
                            </div>
                          </div>
                          <div className="col-lg-12">
                            <div className="mb-4">
                              <label className="form-label">Subject</label>
                              <textarea
                                rows="6"
                                className="form-control"
                                placeholder="Enter Subject"
                                aria-invalid={Boolean(
                                  serviceReqValidation1?.subject?.status ===
                                  false
                                )}
                                onChange={(e) => {
                                  setServiceReqValue1(
                                    "subject",
                                    e.target.value
                                  );
                                }}
                              ></textarea>
                            </div>
                          </div>
                          <div className="col-lg-12">
                            <button
                              type="button"
                              className="btn login-btn w-100"
                              onClick={(e) => {
                                handleSubmitServiceform(e);
                              }}
                            >
                              Submit
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </CustomModal>

      <CustomModal
        open={manPowerContent}
        onClickOutside={() => {
          setManPowerContent(false);
        }}
      >
        <div className="modal-content  m-auto">
          <div className="modal-body p-0 ">
            <div className="container">
              <div className="row d-flex align-items-center justify-content-center  mt-0">
                <div className="container ">
                  <div className="row justify-content-center">
                    <div className="col-lg-12">
                      <div className="row justify-content-center ">
                        <div className="col-lg-4  d-none d-lg-block  px-0">
                          <div className="">
                            <img
                              className="w-100 h-auto modal-left-img"
                              src={
                                selectedManPower === "Man Power"
                                  ? ManPowerNav
                                  : ManPowerNav
                              }
                              alt=""
                            />
                          </div>
                        </div>
                        <div className="col-lg-8 col-md-12">
                          <div
                            className="pt-3 pb-2 pe-5 d-flex justify-content-end align-items-center"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              // setmanpowerNavbarModal(true);
                              setManPowerContent(false);
                            }}
                          >
                            <span className="material-symbols-rounded align-middle color-maroon">
                              arrow_back
                            </span>
                            <h6
                              type="button"
                              className="color-maroon fw-bold mb-0"
                            >
                              Back
                            </h6>
                          </div>
                          <div className="modal-drop-box pt-0 nav-modal-overflow">
                            {/* <h5 className="title banner-heading mb-4"></h5> */}
                            <div className="row ">
                              {getMyTasklevel1?.map((item, index) => (
                                <div
                                  className="col-lg-4 col-md-4 col-6 my-2"
                                  key={index}
                                >
                                  <div
                                    className="dropdown-box"
                                    onClick={() => {
                                      localStorage.setItem(
                                        "category",
                                        item.mainServiceId.title
                                      );
                                      localStorage.setItem(
                                        "subcategory",
                                        item.subServiceL1Id.title
                                      );
                                      localStorage.setItem("title", " ");
                                      localStorage.setItem(
                                        "level2Id",
                                        item._id
                                      );

                                      navigate({
                                        pathname: "/Service",
                                        search: `tab=${item.mainServiceId.title}&subtab=${item.subServiceL1Id.title}&${item.title}`,
                                      });
                                      window.location.reload();
                                      setManPowerContent(false);
                                    }}
                                  >
                                    <img
                                      className="drop-box-img mb-3"
                                      src={item.icon}
                                      alt={item.icon}
                                    />
                                    <div>
                                      <p className="mb-0 fw-bold">
                                        {item.title}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              ))}
                              {/* {DataManPower.map((item, index) => {
                                return (
                                  <div
                                    className="col-lg-4 col-md-4 col-6 my-2"
                                    key={index}
                                  >
                                    <div
                                      className="dropdown-box"
                                      onClick={() => {
                                        localStorage.setItem(
                                          "category",
                                          item.route
                                        );
                                        localStorage.setItem(
                                          "subcategory",
                                          item.type
                                        );
                                        localStorage.setItem(
                                          "title",
                                          item.name
                                        );
                                        navigate(item.route);
                                        setManPowerContent(false);
                                      }}
                                    >
                                      <img
                                        className="drop-box-img mb-3"
                                        src={item.icon}
                                        alt={item.icon}
                                      />
                                      <div>
                                        <p className="mb-0 fw-bold">
                                          {item.name}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                );
                              })} */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </CustomModal>
      <CustomModal
        open={manpowerNavbarModal}
        onClickOutside={() => {
          setmanpowerNavbarModal(false);
        }}
        className="auditModalzindex"
      >
        <div className="modal-content m-auto">
          <div className="modal-body p-0 ">
            <div className="container">
              <div className="row d-flex align-items-center justify-content-center  mt-0">
                <div className="container ">
                  <div className="row">
                    <div className="col-lg-8 ">
                      <div className="modal-drop-box">
                        <div className="row  ">
                          <div className="col-lg-12 col-md-6">
                            <div className="mb-3 d-flex justify-content-between align-items-center">
                              <h5 className="fw-bold color-maroon">
                                MAN POWER
                              </h5>
                              <div
                                className="pt-3 pb-2  d-flex justify-content-end align-items-center"
                                style={{ cursor: "pointer" }}
                                onClick={() => setmanpowerNavbarModal(false)}
                              >
                                <span className="material-symbols-rounded align-middle color-maroon">
                                  arrow_back
                                </span>
                                <h6
                                  type="button"
                                  className="color-maroon fw-bold mb-0"
                                >
                                  Back
                                </h6>
                              </div>
                            </div>
                            <div className="col-megamenu ">
                              <div className="row">
                                {getMyTasklevel1?.map((item, index) => (
                                  <div
                                    className="col-lg-4 col-md-4 col-6 my-2"
                                    key={index}
                                  >
                                    <div
                                      className="dropdown-box"
                                      onClick={() => {
                                        // let route = item.mainServiceId.title.charAt(0).toUpperCase() + item.mainServiceId.title.slice(1);
                                        localStorage.setItem(
                                          "category",
                                          item.mainServiceId.title
                                        );
                                        localStorage.setItem(
                                          "subcategory",
                                          item.title
                                        );
                                        localStorage.setItem("title", " ");
                                        localStorage.setItem(
                                          "level2Id",
                                          item._id
                                        );
                                        navigate({
                                          pathname: "/Service",
                                          search: `tab=${item.mainServiceId.title}&${item.title}`,
                                        });
                                        setmanpowerNavbarModal(false);
                                        window.location.reload();
                                      }}
                                    >
                                      <img
                                        className="drop-box-img mb-3"
                                        src={item.icon}
                                        alt={item.icon}
                                      />
                                      <div>
                                        <p className="mb-0 fw-bold">
                                          {item.title}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                ))}
                                {/* {getMyTasklevel1?.map((tabManPower,index)=>(
                                <div
                                className="col-lg-4 col-md-4 col-6 my-2"
                                key={index}
                                onClick={() => {
                                  GetMyTsksLevel2("active",localStorage?.getItem("mainContent"),tabManPower._id)
                                  setSelectedManPower(tabManPower);
                                  setManPowerContent(true);
                                  setmanpowerNavbarModal(false);
                                }}
                              >
                                <div className="dropdown-box">
                                  <img
                                    className="drop-box-img mb-3"
                                    src={tabManPower.icon}
                                    alt={tabManPower.icon}
                                  />
                                  <div>
                                    <p className="mb-0 fw-bold">
                                      {tabManPower.heading}
                                    </p>
                                  </div>
                                </div>
                              </div>
                              ))} */}
                                {/* {AllManPowerTabs.map((tabManPower, index) => {
                                  return (
                                    <div
                                      className="col-lg-4 col-md-4 col-6 my-2"
                                      key={index}
                                      onClick={() => {
                                        setSelectedManPower(tabManPower);
                                        setManPowerContent(true);
                                        setmanpowerNavbarModal(false);
                                      }}
                                    >
                                      <div className="dropdown-box">
                                        <img
                                          className="drop-box-img mb-3"
                                          src={tabManPower.icon}
                                          alt={tabManPower.icon}
                                        />
                                        <div>
                                          <p className="mb-0 fw-bold">
                                            {tabManPower.heading}
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                    
                                  );
                                })} */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-4 d-md-none px-0 d-lg-block ">
                      <div className="">
                        <img
                          className="w-100 h-auto"
                          src={DropImgModal}
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </CustomModal>
      <CustomModal
        open={legalNavbarModal}
        onClickOutside={() => {
          setlegalNavbarModal(false);
        }}
        className="auditModalzindex"
      >
        <div className="modal-content m-auto">
          <div className="modal-body p-0 ">
            <div className="container">
              <div className="row d-flex align-items-center justify-content-center  mt-0">
                <div className="container ">
                  <div className="row">
                    <div className="col-lg-8 ">
                      <div className="modal-drop-box pt-0">
                        <div className="row  ">
                          <div className="col-lg-12 col-md-6">
                            <div className="mb-3 mt-4 d-flex justify-content-between align-items-center">
                              <h5 className="fw-bold color-maroon">LEGAL</h5>
                              <div
                                className="pt-3 pb-2  d-flex justify-content-end align-items-center"
                                style={{ cursor: "pointer" }}
                                onClick={() => setlegalNavbarModal(false)}
                              >
                                <span className="material-symbols-rounded align-middle color-maroon">
                                  arrow_back
                                </span>
                                <h6
                                  type="button"
                                  className="color-maroon fw-bold mb-0"
                                >
                                  Back
                                </h6>
                              </div>
                            </div>
                            <div className="col-megamenu ">
                              <div className="row">
                                {getMyTasklevel1?.map((tabLegal, index) => (
                                  <div
                                    className="col-lg-4 col-md-4 col-6 my-2"
                                    key={index}
                                    onClick={() => {
                                      GetMyTsksLevel2(
                                        "active",
                                        localStorage?.getItem("mainContent"),
                                        tabLegal._id
                                      );
                                      setSelectedAudit(tabLegal.title);
                                      setSelectedLegal(tabLegal.title);
                                      setDropdownContent(true);
                                      setlegalNavbarModal(false);
                                    }}
                                  >
                                    <div className="dropdown-box">
                                      <img
                                        className="drop-box-img mb-3"
                                        src={tabLegal.icon}
                                        alt={tabLegal.icon}
                                      />
                                      <div>
                                        <p className="mb-0 fw-bold">
                                          {tabLegal.title}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                ))}
                                {/* {AllLegalTabs.map((tabLegal, index) => {
                                  return (
                                    <div
                                      className="col-lg-4 col-md-4 col-6 my-2"
                                      key={index}
                                      onClick={() => {
                                        setSelectedLegal(tabLegal.heading);
                                        setDropdownContent(true);
                                        setlegalNavbarModal(false);
                                      }}
                                    >
                                      <div className="dropdown-box">
                                        <img
                                          className="drop-box-img mb-3"
                                          src={tabLegal.icon}
                                          alt={tabLegal.icon}
                                        />
                                        <div>
                                          <p className="mb-0 fw-bold">
                                            {tabLegal.heading}
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  );
                                })} */}
                              </div>
                            </div>
                          </div>
                          {/* <div className="col-lg-12 col-md-6 mt-5">
                                <div className="mb-3">
                                  <h5 className="fw-bold color-maroon">
                                    LEGAL SERVICES
                                  </h5>
                                </div>
                                <div className="col-megamenu ">
                                  <div className="row">
                                    {AllLegalTabs2.map((tabLegal2, index) => {
                                      return (
                                        <div
                                          className="col-lg-4 col-md-4 col-6 my-2"
                                          key={index}
                                          onClick={() => {
                                            setSelectedLegal(tabLegal2);
                                            setDropdownContent2(true);
                                          }}
                                        >
                                          <div className="dropdown-box">
                                        
                                            <div>
                                              <p className="mb-0 fw-bold">
                                                {tabLegal2}
                                              </p>
                                            </div>
                                          </div>
                                        </div>
                                      );
                                    })}
                                  </div>
                                </div>
                              </div> */}
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-4 d-md-none d-none px-0 d-lg-block ">
                      <div className="">
                        <img className="w-100 h-auto" src={LegalNav} alt="" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </CustomModal>
      <CustomModal
        open={auctionContent}
        onClickOutside={() => {
          setAuctionContent(false);
        }}
      >
        <div className="modal-content  m-auto">
          <div className="modal-body p-0 ">
            <div className="container">
              <div className="row d-flex align-items-center justify-content-center  mt-0">
                <div className="container ">
                  <div className="row justify-content-center">
                    <div className="col-lg-12">
                      <div className="row justify-content-center ">
                        <div className="col-lg-4  d-none d-lg-block  px-0">
                          <div className="">
                            <img
                              className="w-100 h-auto modal-left-img"
                              src={
                                selectedManPower === "Man Power"
                                  ? ManPowerNav
                                  : ManPowerNav
                              }
                              alt=""
                            />
                          </div>
                        </div>
                        <div className="col-lg-8 col-md-12">
                          <div
                            className="pt-3 pb-2 pe-5 d-flex justify-content-end align-items-center"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              setotherserviceNavbarModal(true);
                              setAuctionContent(false);
                            }}
                          >
                            <span className="material-symbols-rounded align-middle color-maroon">
                              arrow_back
                            </span>
                            <h6
                              type="button"
                              className="color-maroon fw-bold mb-0"
                            >
                              Back
                            </h6>
                          </div>
                          <div className="modal-drop-box pt-0 nav-modal-overflow">
                            {/* <h5 className="title banner-heading mb-4"></h5> */}
                            <div className="row ">
                              {data?.map((item, index) => (
                                <div
                                  className="col-lg-4 col-md-4 col-6 my-2"
                                  key={index}
                                >
                                  <div
                                    className="dropdown-box"
                                    onClick={() => {
                                      if (item?.name === "Apartment") {
                                        navigate(
                                          `/OtherService&tab=Apartment`
                                        );
                                      } else if (
                                        item?.name === "Independent Villa"
                                      ) {
                                        navigate(`/OtherService&tab=Villa`);
                                      } else if (
                                        item?.name === "Individual House"
                                      ) {
                                        navigate(
                                          `/OtherService&tab=IndividualHouse`
                                        );
                                      } else if (item?.name === "Plot") {
                                        navigate(`/OtherService&tab=BSPlot`);
                                      } else if (item?.name === "Gold") {
                                        navigate(`/OtherService&tab=Gold`);
                                      } else if (
                                        item?.name === "Two Wheeler"
                                      ) {
                                        navigate(
                                          `/OtherService&tab=TwoWheeler`
                                        );
                                      } else if (
                                        item?.name === "Four Wheeler"
                                      ) {
                                        navigate(
                                          `/OtherService&tab=FourWheeler`
                                        );
                                      }

                                      setAuctionContent(false);
                                    }}
                                  >
                                    <img
                                      className="drop-box-img mb-3"
                                      src={item.icon}
                                      alt={item.icon}
                                    />
                                    <div>
                                      <p className="mb-0 fw-bold">
                                        {item.name}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              ))}
                              {/* {DataManPower.map((item, index) => {
                                return (
                                  <div
                                    className="col-lg-4 col-md-4 col-6 my-2"
                                    key={index}
                                  >
                                    <div
                                      className="dropdown-box"
                                      onClick={() => {
                                        localStorage.setItem(
                                          "category",
                                          item.route
                                        );
                                        localStorage.setItem(
                                          "subcategory",
                                          item.type
                                        );
                                        localStorage.setItem(
                                          "title",
                                          item.name
                                        );
                                        navigate(item.route);
                                        setManPowerContent(false);
                                      }}
                                    >
                                      <img
                                        className="drop-box-img mb-3"
                                        src={item.icon}
                                        alt={item.icon}
                                      />
                                      <div>
                                        <p className="mb-0 fw-bold">
                                          {item.name}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                );
                              })} */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </CustomModal>
      <CustomModal
        open={legalNavbarModal}
        onClickOutside={() => {
          setlegalNavbarModal(false);
        }}
        className="auditModalzindex"
      >
        <div className="modal-content m-auto">
          <div className="modal-body p-0 ">
            <div className="container">
              <div className="row d-flex align-items-center justify-content-center  mt-0">
                <div className="container ">
                  <div className="row">
                    <div className="col-lg-8 ">
                      <div className="modal-drop-box pt-0">
                        <div className="row  ">
                          <div className="col-lg-12 col-md-6">
                            <div className="mb-3 mt-4 d-flex justify-content-between align-items-center">
                              <h5 className="fw-bold color-maroon">LEGAL</h5>
                              <div
                                className="pt-3 pb-2  d-flex justify-content-end align-items-center"
                                style={{ cursor: "pointer" }}
                                onClick={() => setlegalNavbarModal(false)}
                              >
                                <span className="material-symbols-rounded align-middle color-maroon">
                                  arrow_back
                                </span>
                                <h6
                                  type="button"
                                  className="color-maroon fw-bold mb-0"
                                >
                                  Back
                                </h6>
                              </div>
                            </div>
                            <div className="col-megamenu ">
                              <div className="row">
                                {getMyTasklevel1?.map((tabLegal, index) => (
                                  <div
                                    className="col-lg-4 col-md-4 col-6 my-2"
                                    key={index}
                                    onClick={() => {
                                      GetMyTsksLevel2(
                                        "active",
                                        localStorage?.getItem("mainContent"),
                                        tabLegal._id
                                      );
                                      setSelectedAudit(tabLegal.title);
                                      setSelectedLegal(tabLegal.title);
                                      setDropdownContent(true);
                                      setlegalNavbarModal(false);
                                    }}
                                  >
                                    <div className="dropdown-box">
                                      <img
                                        className="drop-box-img mb-3"
                                        src={tabLegal.icon}
                                        alt={tabLegal.icon}
                                      />
                                      <div>
                                        <p className="mb-0 fw-bold">
                                          {tabLegal.title}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                ))}
                                {/* {AllLegalTabs.map((tabLegal, index) => {
                                  return (
                                    <div
                                      className="col-lg-4 col-md-4 col-6 my-2"
                                      key={index}
                                      onClick={() => {
                                        setSelectedLegal(tabLegal.heading);
                                        setDropdownContent(true);
                                        setlegalNavbarModal(false);
                                      }}
                                    >
                                      <div className="dropdown-box">
                                        <img
                                          className="drop-box-img mb-3"
                                          src={tabLegal.icon}
                                          alt={tabLegal.icon}
                                        />
                                        <div>
                                          <p className="mb-0 fw-bold">
                                            {tabLegal.heading}
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  );
                                })} */}
                              </div>
                            </div>
                          </div>
                          {/* <div className="col-lg-12 col-md-6 mt-5">
                                <div className="mb-3">
                                  <h5 className="fw-bold color-maroon">
                                    LEGAL SERVICES
                                  </h5>
                                </div>
                                <div className="col-megamenu ">
                                  <div className="row">
                                    {AllLegalTabs2.map((tabLegal2, index) => {
                                      return (
                                        <div
                                          className="col-lg-4 col-md-4 col-6 my-2"
                                          key={index}
                                          onClick={() => {
                                            setSelectedLegal(tabLegal2);
                                            setDropdownContent2(true);
                                          }}
                                        >
                                          <div className="dropdown-box">
                                        
                                            <div>
                                              <p className="mb-0 fw-bold">
                                                {tabLegal2}
                                              </p>
                                            </div>
                                          </div>
                                        </div>
                                      );
                                    })}
                                  </div>
                                </div>
                              </div> */}
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-4 d-md-none d-none px-0 d-lg-block ">
                      <div className="">
                        <img className="w-100 h-auto" src={LegalNav} alt="" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </CustomModal>
      <CustomModal
        open={auditNavbarModal}
        onClickOutside={() => {
          setauditNavbarModal(false);
        }}
        className="auditModalzindex"
      >
        <div className="modal-content m-auto">
          <div className="modal-body p-0 ">
            <div className="container">
              <div className="row d-flex align-items-center justify-content-center  mt-0">
                <div className="container ">
                  <div className="row ">
                    <div className="col-lg-8 ">
                      <div className="modal-drop-box">
                        <div className="row">
                          <div className="col-lg-12 col-md-6 ">
                            <div className="mb-3 d-flex justify-content-between align-items-center">
                              <h5 className="color-maroon fw-bold">AUDIT</h5>
                              <div
                                className="pt-3 pb-2  d-flex justify-content-end align-items-center"
                                style={{ cursor: "pointer" }}
                                onClick={() => setauditNavbarModal(false)}
                              >
                                <span className="material-symbols-rounded align-middle color-maroon">
                                  arrow_back
                                </span>
                                <h6
                                  type="button"
                                  className="color-maroon fw-bold mb-0"
                                >
                                  Back
                                </h6>
                              </div>
                            </div>
                            <div className="col-megamenu ">
                              {/* {AllAuditTabs.map((tabAudit, index) => {
                                    return (
                                      <h6
                                        className="drop-title"
                                        key={index}
                                        onClick={() => {
                                          setSelectedAudit(tabAudit);
                                          setAuditContent(true);
                                        }}
                                      >
                                        <span className="material-symbols-rounded color-maroon align-middle">
                                          double_arrow
                                        </span>{" "}
                                        <span className="align-middle">
                                          {tabAudit}
                                        </span>
                                      </h6>
                                    );
                                  })} */}
                              <div className="row">
                                {getMyTasklevel1?.map((tabAudit, index) => (
                                  <div
                                    className="col-lg-4 col-md-4 col-6 my-2"
                                    key={index}
                                    onClick={() => {
                                      GetMyTsksLevel2(
                                        "active",
                                        localStorage?.getItem("mainContent"),
                                        tabAudit._id
                                      );
                                      setSelectedAudit(tabAudit.title);
                                      setAuditContent(true);
                                      setauditNavbarModal(false);
                                    }}
                                  >
                                    <div className="dropdown-box">
                                      <img
                                        className="drop-box-img mb-3"
                                        src={tabAudit.icon}
                                        alt={tabAudit.icon}
                                      />
                                      <div>
                                        <p className="mb-0 fw-bold">
                                          {tabAudit.title}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                ))}
                                {/* {AllAuditTabs.map((tabAudit, index) => {
                                  return (
                                    <div
                                      className="col-lg-4 col-md-4 col-6 my-2"
                                      key={index}
                                      onClick={() => {
                                        setSelectedAudit(tabAudit.heading);
                                        setAuditContent(true);
                                        setauditNavbarModal(false);
                                      }}
                                    >
                                      <div className="dropdown-box">
                                        <img
                                          className="drop-box-img mb-3"
                                          src={tabAudit.icon}
                                          alt={tabAudit.icon}
                                        />
                                        <div>
                                          <p className="mb-0 fw-bold">
                                            {tabAudit.heading}
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  );
                                })} */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-4 px-0 d-md-none d-none d-lg-block">
                      <div className="">
                        {/* <img
                              className="w-100 h-auto"
                              src={
                                selectedAudit === "Startup"
                                  ? DropImgModal1
                                  : selectedAudit === "Trademark"
                                  ? DropImgModal2
                                  : selectedAudit === "GST"
                                  ? DropImgModal3
                                  : selectedAudit === "IncomeTax"
                                  ? DropImgModal4
                                  : DropImgModal5
                              }
                              alt=""
                            /> */}
                        <img
                          className="w-100 h-auto"
                          src={DropImgModal}
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </CustomModal>
      <CustomModal
        open={dropdownContent}
        onClickOutside={() => {
          setDropdownContent(false);
        }}
      >
        <div className="modal-content  m-auto">
          <div className="modal-body p-0 ">
            <div className="container">
              <div className="row d-flex align-items-center justify-content-center  mt-0">
                <div className="container ">
                  <div className="row justify-content-center">
                    <div className="col-lg-12">
                      <div className="row justify-content-center ">
                        <div className="col-lg-4 col-md-4 d-none d-lg-block  px-0">
                          <div className="">
                            <img
                              className="w-100 h-auto"
                              src={NavFam}
                              //  src={selectedLegal === "Civil Cases"
                              //  ? NavFam
                              //  :selectedLegal === "Family Cases"
                              //  ? NavFam
                              //  :selectedLegal === "Rent Control Cases"
                              //  ? NavFam
                              //  :selectedLegal === "Employee and Employer Cases"
                              //  ? NavFam
                              //  :selectedLegal === "Banking & Financial Issues"
                              //  ? NavFam
                              //  :selectedLegal === "Insurance Cases"
                              //  ? NavFam
                              //  :selectedLegal === "Cyber Crime Cases"
                              //  ? NavFam
                              //  :selectedLegal === "Cheque Bounces"
                              //  ? NavFam
                              //  :selectedLegal === "Medical Negligence"
                              //  ? NavFam
                              //  :selectedLegal === "Motor Vehicle Accident Cases"
                              //  ? NavFam
                              //  :selectedLegal === "Arbitration & Conciliation"
                              //  ? NavFam
                              //  :selectedLegal === "Regular Civil Appeal"
                              //  ? NavFam
                              //  :selectedLegal === "Civil Revision"
                              //  ? NavFam
                              //  :selectedLegal === "Executing Petition"
                              //  ? NavFam
                              //  :selectedLegal === "Caveat Application"
                              //  ? NavFam
                              //  :selectedLegal === "Insolvency"
                              //  ? NavFam
                              //  :selectedLegal === "Customs & Central Excise"
                              //  ? NavFam
                              //  :selectedLegal === "Land Acquisition"
                              //  ? NavFam
                              //  :selectedLegal === "Companies Act"
                              //  ? NavFam
                              //  :selectedLegal === "Tribunals"
                              //  ? NavFam
                              //  :selectedLegal === "Intellectual Property Rights"
                              //  ? NavFam
                              //  :selectedLegal === "Sessions Case"
                              // }
                              alt=""
                            />
                          </div>
                        </div>
                        <div className="col-lg-8 col-md-8">
                          <div
                            className="pt-3 pb-2 pe-5 d-flex justify-content-end align-items-center"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              setlegalNavbarModal(true);
                              setDropdownContent(false);
                            }}
                          >
                            <span className="material-symbols-rounded align-middle color-maroon">
                              arrow_back
                            </span>
                            <h6
                              type="button"
                              className="color-maroon fw-bold mb-0"
                            >
                              Back
                            </h6>
                          </div>
                          <div className="modal-drop-box pt-0">
                            {/* <h5 className="title banner-heading mb-4">
                              Personal / Family
                            </h5> */}
                            <div className="row">
                              {getMyTasklevel2?.map((item, index) => (
                                <div
                                  className="col-lg-4 col-md-6 col-6 my-2"
                                  key={index}
                                >
                                  <div
                                    className="dropdown-box"
                                    onClick={() => {
                                      let route =
                                        item.mainServiceId.title
                                          .charAt(0)
                                          .toUpperCase() +
                                        item.mainServiceId.title.slice(1);
                                      localStorage.setItem(
                                        "category",
                                        item.mainServiceId.title
                                      );
                                      localStorage.setItem(
                                        "subcategory",
                                        item.subServiceL1Id.title
                                      );
                                      localStorage.setItem(
                                        "title",
                                        item.title
                                      );
                                      localStorage.setItem(
                                        "level2Id",
                                        item._id
                                      );
                                      navigate({
                                        pathname: "/Service",
                                        search: `tab=${item.mainServiceId.title}&subtab=${item.subServiceL1Id.title}&${item.title}`,
                                      });
                                      setDropdownContent(false);
                                      window.location.reload();
                                      // GetMyTsks("active",item._id)
                                    }}
                                  >
                                    <img
                                      className="drop-box-img mb-3"
                                      src={item.icon}
                                      alt={item.icon}
                                    />
                                    <div>
                                      <p className="mb-0 fw-bold">
                                        {item.title}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              ))}
                              {/* {DataLegal.filter(
                                (item) => item.type === selectedLegal
                              ).map((item, index) => {
                                return (
                                  <div
                                    className="col-lg-4 col-md-6 col-6 my-2"
                                    key={index}
                                  >
                                    <div
                                      className="dropdown-box"
                                      onClick={() => {
                                        localStorage.setItem(
                                          "category",
                                          item.route
                                        );
                                        localStorage.setItem(
                                          "subcategory",
                                          item.type
                                        );
                                        localStorage.setItem(
                                          "title",
                                          item.name
                                        );
                                        navigate(item.route);
                                        setDropdownContent(false);
                                       
                                      }}
                                    >
                                      <img
                                        className="drop-box-img mb-3"
                                        src={item.icon}
                                        alt={item.icon}
                                      />
                                      <div>
                                        <p className="mb-0 fw-bold">
                                          {item.name}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                );
                              })} */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </CustomModal>
      <CustomModal
        open={auditContent}
        onClickOutside={() => {
          setAuditContent(false);
        }}
      >
        <div className="modal-content  m-auto">
          <div className="modal-body p-0 ">
            <div className="container">
              {/* <span
                className="material-symbols-rounded filled-icon close-icon"
                style={{
                  textShadow: "rgb(171 167 167 / 44%) 0px 3px 6px",
                }}
                onClick={() => {
                  setAuditContent(false);
                }}
              >
                close
              </span> */}
              <div className="row d-flex align-items-center justify-content-center  mt-0">
                <div className="container ">
                  <div className="row justify-content-center">
                    <div className="col-lg-12">
                      <div className="row justify-content-center ">
                        <div className="col-lg-4  d-none d-lg-block  px-0">
                          <div className="">
                            {/* <img className="w-100 h-auto" src={NavFam} alt="" /> */}
                            <img
                              className="w-100 h-auto modal-left-img"
                              src={
                                selectedAudit === "Startup"
                                  ? DropImgModal1
                                  : selectedAudit === "Trademark"
                                    ? DropImgModal2
                                    : selectedAudit === "GST"
                                      ? DropImgModal3
                                      : selectedAudit === "IncomeTax"
                                        ? DropImgModal4
                                        : DropImgModal5
                              }
                              alt=""
                            />
                          </div>
                        </div>
                        <div className="col-lg-8 col-md-12">
                          <div
                            className="pt-3 pb-2 pe-5 d-flex justify-content-end align-items-center"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              setauditNavbarModal(true);
                              setAuditContent(false);
                            }}
                          >
                            <span className="material-symbols-rounded align-middle color-maroon">
                              arrow_back
                            </span>
                            <h6
                              type="button"
                              className="color-maroon fw-bold mb-0"
                            >
                              Back
                            </h6>
                          </div>
                          <div className="modal-drop-box pt-0 nav-modal-overflow">
                            {/* <h5 className="title banner-heading mb-4"></h5> */}
                            <div className="row">
                              {getMyTasklevel2?.map((item, index) => (
                                <div
                                  className="col-lg-4 col-md-4 col-6 my-2"
                                  key={index}
                                >
                                  <div
                                    className="dropdown-box"
                                    onClick={() => {
                                      let route =
                                        item.mainServiceId.title
                                          .charAt(0)
                                          .toUpperCase() +
                                        item.mainServiceId.title.slice(1);
                                      localStorage.setItem(
                                        "category",
                                        item.mainServiceId.title
                                      );
                                      localStorage.setItem(
                                        "subcategory",
                                        item.subServiceL1Id.title
                                      );
                                      localStorage.setItem(
                                        "title",
                                        item.title
                                      );

                                      localStorage.setItem(
                                        "level2Id",
                                        item._id
                                      );
                                      navigate({
                                        pathname: "/Service",
                                        search: `tab=${item.mainServiceId.title}&subtab=${item.subServiceL1Id.title}&${item.title}`,
                                      });
                                      // navigate(`/Service`);
                                      setAuditContent(false);
                                      window.location.reload();
                                    }}
                                  >
                                    <img
                                      className="drop-box-img mb-3"
                                      src={item.icon}
                                      alt={item.icon}
                                    />
                                    <div>
                                      <p className="mb-0 fw-bold">
                                        {item.title}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              ))}
                              {/* {Data.filter(
                                (item) => item.type === selectedAudit
                              ).map((item, index) => {
                                return (
                                  <div
                                    className="col-lg-4 col-md-4 col-6 my-2"
                                    key={index}
                                  >
                                    <div
                                      className="dropdown-box"
                                      onClick={() => {
                                        localStorage.setItem(
                                          "category",
                                          item.route
                                        );
                                        localStorage.setItem(
                                          "subcategory",
                                          item.type
                                        );
                                        localStorage.setItem(
                                          "title",
                                          item.name
                                        );
                                        navigate(item.route);
                                        setAuditContent(false);
                                      }}
                                    >
                                      <img
                                        className="drop-box-img mb-3"
                                        src={item.icon}
                                        alt={item.icon}
                                      />
                                      <div>
                                        <p className="mb-0 fw-bold">
                                          {item.name}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                );
                              })} */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </CustomModal>
      <CustomModal
        open={BuySellContent}
        onClickOutside={() => {
          setBuySellContent(false);
        }}
      >
        <div className="modal-content  m-auto">
          <div className="modal-body p-0 ">
            <div className="container">
              <div className="row d-flex align-items-center justify-content-center  mt-0">
                <div className="container ">
                  <div className="row justify-content-center">
                    <div className="col-lg-12">
                      <div className="row justify-content-center ">
                        <div className="col-lg-4  d-none d-lg-block  px-0">
                          <div className="">
                            <img
                              className="w-100 h-auto modal-left-img"
                              src={
                                selectedManPower === "Man Power"
                                  ? ManPowerNav
                                  : ManPowerNav
                              }
                              alt=""
                            />
                          </div>
                        </div>
                        <div className="col-lg-8 col-md-12">
                          <div
                            className="pt-3 pb-2 pe-5 d-flex justify-content-end align-items-center"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              setotherserviceNavbarModal(true);
                              setBuySellContent(false);
                            }}
                          >
                            <span className="material-symbols-rounded align-middle color-maroon">
                              arrow_back
                            </span>
                            <h6
                              type="button"
                              className="color-maroon fw-bold mb-0"
                            >
                              Back
                            </h6>
                          </div>
                          <div className="modal-drop-box pt-0 nav-modal-overflow">
                            {/* <h5 className="title banner-heading mb-4"></h5> */}
                            <div className="row ">
                              {data1?.map((item, index) => (
                                <div
                                  className="col-lg-4 col-md-4 col-6 my-2"
                                  key={index}
                                >
                                  <div
                                    className="dropdown-box"
                                    onClick={() => {
                                      if (item?.name === "Apartment") {
                                        navigate(
                                          `/OtherService&tab=BSApartment`
                                        );
                                      } else if (
                                        item?.name === "Independent Villa"
                                      ) {
                                        navigate(`/OtherService&tab=BSVilla`);
                                      } else if (
                                        item?.name === "Individual House"
                                      ) {
                                        navigate(
                                          `/OtherService&tab=BSIndividualHouse`
                                        );
                                      } else if (item?.name === "Plot") {
                                        navigate(`/OtherService&tab=BSPlot`);
                                      } else if (item?.name === "Gold") {
                                        navigate(`/OtherService&tab=BSGold`);
                                      } else if (
                                        item?.name === "Two Wheeler"
                                      ) {
                                        navigate(
                                          `/OtherService&tab=BSTwoWheeler`
                                        );
                                      } else if (
                                        item?.name === "Four Wheeler"
                                      ) {
                                        navigate(
                                          `/OtherService&tab=BSFourWheeler`
                                        );
                                      }

                                      setBuySellContent(false);
                                    }}
                                  >
                                    <img
                                      className="drop-box-img mb-3"
                                      src={item.icon}
                                      alt={item.icon}
                                    />
                                    <div>
                                      <p className="mb-0 fw-bold">
                                        {item.name}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              ))}
                              {/* {DataManPower.map((item, index) => {
                                return (
                                  <div
                                    className="col-lg-4 col-md-4 col-6 my-2"
                                    key={index}
                                  >
                                    <div
                                      className="dropdown-box"
                                      onClick={() => {
                                        localStorage.setItem(
                                          "category",
                                          item.route
                                        );
                                        localStorage.setItem(
                                          "subcategory",
                                          item.type
                                        );
                                        localStorage.setItem(
                                          "title",
                                          item.name
                                        );
                                        navigate(item.route);
                                        setManPowerContent(false);
                                      }}
                                    >
                                      <img
                                        className="drop-box-img mb-3"
                                        src={item.icon}
                                        alt={item.icon}
                                      />
                                      <div>
                                        <p className="mb-0 fw-bold">
                                          {item.name}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                );
                              })} */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </CustomModal>
      <CustomModal
        open={otherserviceNavbarModal}
        onClickOutside={() => {
          setotherserviceNavbarModal(false);
        }}
        className="auditModalzindex"
      >
        <div className="modal-content m-auto">
          <div className="modal-body p-0 ">
            <div className="container">
              <div className="row d-flex align-items-center justify-content-center  mt-0">
                <div className="container ">
                  <div className="row">
                    <div className="col-lg-8 ">
                      <div className="modal-drop-box">
                        <div className="row">
                          <div className="col-lg-12 col-md-6">
                            <div className="mb-3 d-flex justify-content-between align-items-center">
                              <h5 className="fw-bold color-maroon">
                                Other Services
                              </h5>
                              <div
                                className="pt-3 pb-2  d-flex justify-content-end align-items-center"
                                style={{ cursor: "pointer" }}
                                onClick={() =>
                                  setotherserviceNavbarModal(false)
                                }
                              >
                                <span className="material-symbols-rounded align-middle color-maroon">
                                  arrow_back
                                </span>
                                <h6
                                  type="button"
                                  className="color-maroon fw-bold mb-0"
                                >
                                  Back
                                </h6>
                              </div>
                            </div>
                            <div className="col-megamenu ">
                              <div className="row">
                                {getMyTasklevel1?.map((tabOS, index) => (
                                  <div
                                    className="col-lg-4  col-md-4 col-6 my-2"
                                    key={index}
                                    onClick={() => {
                                      GetMyTsksLevel2(
                                        "active",
                                        localStorage?.getItem("mainContent"),
                                        tabOS._id
                                      );
                                      setSelectedOS(tabOS.title);
                                      //console.log(tabOS.title);
                                      if (tabOS.title === "Auctions") {
                                        setAuctionContent(true);
                                        setotherserviceNavbarModal(false);
                                      } else if (
                                        tabOS.title === "Buying and Selling"
                                      ) {
                                        setBuySellContent(true);
                                        setotherserviceNavbarModal(false);
                                      } else {
                                        setOSContent(true);
                                        setotherserviceNavbarModal(false);
                                      }
                                    }}
                                  >
                                    <div className="dropdown-box">
                                      <img
                                        className="drop-box-img mb-3"
                                        src={tabOS.icon}
                                        alt={tabOS.icon}
                                      />
                                      <div>
                                        <p className="mb-0 fw-bold">
                                          {tabOS.title}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                ))}
                                {/* {OtherServicesTabs.map((tabOS, index) => {
                                  return (
                                    <div
                                      className="col-lg-4  col-md-4 col-6 my-2"
                                      key={index}
                                      onClick={() => {
                                        setSelectedOS(tabOS.heading);
                                        setOSContent(true);
                                        setotherserviceNavbarModal(false);
                                      }}
                                    >
                                      <div className="dropdown-box">
                                        <img
                                          className="drop-box-img mb-3"
                                          src={tabOS.icon}
                                          alt={tabOS.icon}
                                        />
                                        <div>
                                          <p className="mb-0 fw-bold">
                                            {tabOS.heading}
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  );
                                })} */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-4 px-0 d-md-none d-none d-lg-block ">
                      <div className="">
                        <img
                          className="w-100 h-auto os-nav-right"
                          src={OsNav}
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </CustomModal>
      <CustomModal
        open={otherserviceNavbarModal1}
        onClickOutside={() => {
          setotherserviceNavbarModal1(false);
        }}
        className="auditModalzindex"
      >
        <div className="modal-content m-auto">
          <div className="modal-body p-0 ">
            <div className="container">
              <div className="row d-flex align-items-center justify-content-center  mt-0">
                <div className="container ">
                  <div className="row">
                    <div className="col-lg-8 ">
                      <div className="modal-drop-box">
                        <div className="row">
                          <div className="col-lg-12 col-md-6">
                            <div className="mb-3 d-flex justify-content-between align-items-center">
                              <h5 className="fw-bold color-maroon">
                                Other Services
                              </h5>
                              <div
                                className="pt-3 pb-2  d-flex justify-content-end align-items-center"
                                style={{ cursor: "pointer" }}
                                onClick={() =>
                                  setotherserviceNavbarModal1(false)
                                }
                              >
                                <span className="material-symbols-rounded align-middle color-maroon">
                                  arrow_back
                                </span>
                                <h6
                                  type="button"
                                  className="color-maroon fw-bold mb-0"
                                >
                                  Back
                                </h6>
                              </div>
                            </div>
                            <div className="col-megamenu ">
                              <div className="row">
                                {getMyTasklevel1?.map((tabOS, index) => (
                                  <div
                                    className="col-lg-4  col-md-4 col-6 my-2"
                                    key={index}
                                    onClick={() => {
                                      GetMyTsksLevel2(
                                        "active",
                                        localStorage?.getItem("mainContent"),
                                        tabOS._id
                                      );
                                      setSelectedOS(tabOS.title);

                                      setOSContent1(true);
                                      setotherserviceNavbarModal1(false);
                                    }}
                                  >
                                    <div className="dropdown-box">
                                      <img
                                        className="drop-box-img mb-3"
                                        src={tabOS.icon}
                                        alt={tabOS.icon}
                                      />
                                      <div>
                                        <p className="mb-0 fw-bold">
                                          {tabOS.title}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                ))}
                                {/* {OtherServicesTabs.map((tabOS, index) => {
                                  return (
                                    <div
                                      className="col-lg-4  col-md-4 col-6 my-2"
                                      key={index}
                                      onClick={() => {
                                        setSelectedOS(tabOS.heading);
                                        setOSContent(true);
                                        setotherserviceNavbarModal(false);
                                      }}
                                    >
                                      <div className="dropdown-box">
                                        <img
                                          className="drop-box-img mb-3"
                                          src={tabOS.icon}
                                          alt={tabOS.icon}
                                        />
                                        <div>
                                          <p className="mb-0 fw-bold">
                                            {tabOS.heading}
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  );
                                })} */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-4 px-0 d-md-none d-none d-lg-block ">
                      <div className="">
                        <img
                          className="w-100 h-auto os-nav-right"
                          src={OsNav}
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </CustomModal>
      <CustomModal
        open={OSContent}
        onClickOutside={() => {
          setOSContent(false);
        }}
      >
        <div className="modal-content  m-auto">
          <div className="modal-body p-0 ">
            <div className="container">
              <div className="row d-flex align-items-center justify-content-center  mt-0">
                <div className="container ">
                  <div className="row justify-content-center">
                    <div className="col-lg-12">
                      <div className="row justify-content-center ">
                        <div className="col-lg-4  d-none d-lg-block  px-0">
                          <div className="">
                            {/* <img className="w-100 h-auto" src={NavFam} alt="" /> */}
                            <img
                              className="w-100 h-auto modal-left-img"
                              src={
                                selectedOS === "Marketing"
                                  ? DMLeft
                                  : selectedOS === "Auction"
                                    ? AuctionNav
                                    : selectedOS === "Buy/Sell"
                                      ? BuysellNav
                                      : BuysellNav
                              }
                              alt=""
                            />
                          </div>
                        </div>
                        <div className="col-lg-8 col-md-12">
                          <div
                            className="pt-3 pb-2 pe-5 d-flex justify-content-end align-items-center"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              setotherserviceNavbarModal(true);
                              setOSContent(false);
                            }}
                          >
                            <span className="material-symbols-rounded align-middle color-maroon">
                              arrow_back
                            </span>
                            <h6
                              type="button"
                              className="color-maroon fw-bold mb-0"
                            >
                              Back
                            </h6>
                          </div>
                          <div className="modal-drop-box nav-modal-overflow">
                            {/* <h5 className="title banner-heading mb-4"></h5> */}
                            <div className="row">
                              {getMyTasklevel2?.map((item, index) => (
                                <div
                                  className="col-lg-4 col-md-4 col-6 my-2"
                                  key={index}
                                >
                                  <div
                                    className="dropdown-box"
                                    onClick={() => {
                                      let route =
                                        item.mainServiceId.title
                                          .charAt(0)
                                          .toUpperCase() +
                                        item.mainServiceId.title.slice(1);
                                      localStorage.setItem(
                                        "category",
                                        item.mainServiceId.title
                                      );
                                      localStorage.setItem(
                                        "subcategory",
                                        item.subServiceL1Id.title
                                      );
                                      localStorage.setItem(
                                        "title",
                                        item.title
                                      );
                                      localStorage.setItem(
                                        "level2Id",
                                        item._id
                                      );
                                      navigate({
                                        pathname: "/Service",
                                        search: `tab=${item.mainServiceId.title}&subtab=${item.subServiceL1Id.title}&${item.title}`,
                                      });
                                      setOSContent(false);
                                      window.location.reload();
                                    }}
                                  >
                                    <img
                                      className="drop-box-img mb-3"
                                      src={item.icon}
                                      alt={item.icon}
                                    />
                                    <div>
                                      <p className="mb-0 fw-bold">
                                        {item.title}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              ))}
                              {/* {getMyTasklevel2.filter(
                                (item) => item.type === selectedOS
                              ).map((item, index) => {
                                return (
                                  <div
                                    className="col-lg-4 col-md-4 col-6 my-2"
                                    key={index}
                                  >
                                    <div
                                      className="dropdown-box"
                                      onClick={() => {
                                        let route = item.mainServiceId.title.charAt(0).toUpperCase() + item.mainServiceId.title.slice(1);
                                        localStorage.setItem(
                                          "category",
                                          item.route
                                        );
                                        localStorage.setItem(
                                          "subcategory",
                                          item.type
                                        );
                                        localStorage.setItem(
                                          "title",
                                          item.name
                                        );
                                        navigate(route);
                                        setOSContent(false);
                                      }}
                                    >
                                      <img
                                        className="drop-box-img mb-3"
                                        src={item.icon}
                                        alt={item.icon}
                                      />
                                      <div>
                                        <p className="mb-0 fw-bold">
                                          {item.name}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                );
                              })} */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </CustomModal>
      <CustomModal
        open={OSContent1}
        onClickOutside={() => {
          setOSContent1(false);
        }}
      >
        <div className="modal-content  m-auto">
          <div className="modal-body p-0 ">
            <div className="container">
              <div className="row d-flex align-items-center justify-content-center  mt-0">
                <div className="container ">
                  <div className="row justify-content-center">
                    <div className="col-lg-12">
                      <div className="row justify-content-center ">
                        <div className="col-lg-4  d-none d-lg-block  px-0">
                          <div className="">
                            {/* <img className="w-100 h-auto" src={NavFam} alt="" /> */}
                            <img
                              className="w-100 h-auto modal-left-img"
                              src={
                                selectedOS === "Marketing"
                                  ? DMLeft
                                  : selectedOS === "Auction"
                                    ? AuctionNav
                                    : selectedOS === "Buy/Sell"
                                      ? BuysellNav
                                      : BuysellNav
                              }
                              alt=""
                            />
                          </div>
                        </div>
                        <div className="col-lg-8 col-md-12">
                          <div
                            className="pt-3 pb-2 pe-5 d-flex justify-content-end align-items-center"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              setotherserviceNavbarModal1(true);
                              setOSContent1(false);
                            }}
                          >
                            <span className="material-symbols-rounded align-middle color-maroon">
                              arrow_back
                            </span>
                            <h6
                              type="button"
                              className="color-maroon fw-bold mb-0"
                            >
                              Back
                            </h6>
                          </div>
                          <div className="modal-drop-box nav-modal-overflow">
                            {/* <h5 className="title banner-heading mb-4"></h5> */}
                            <div className="row">
                              {getMyTasklevel2?.map((item, index) => (
                                <div
                                  className="col-lg-4 col-md-4 col-6 my-2"
                                  key={index}
                                >
                                  <div
                                    className="dropdown-box"
                                    onClick={() => {
                                      let route =
                                        item.mainServiceId.title
                                          .charAt(0)
                                          .toUpperCase() +
                                        item.mainServiceId.title.slice(1);
                                      localStorage.setItem(
                                        "category",
                                        item.mainServiceId.title
                                      );

                                      localStorage.setItem(
                                        "subcategory",
                                        item.subServiceL1Id.title
                                      );

                                      localStorage.setItem(
                                        "title",
                                        item.title
                                      );

                                      localStorage.setItem(
                                        "level2Id",
                                        item._id
                                      );
                                      navigate({
                                        pathname: "/Service",
                                        search: `tab=${item.mainServiceId.title}&subtab=${item.subServiceL1Id.title}&${item.title}`,
                                      });
                                      setOSContent1(false);
                                      window.location.reload();
                                    }}
                                  >
                                    <img
                                      className="drop-box-img mb-3"
                                      src={item.icon}
                                      alt={item.icon}
                                    />
                                    <div>
                                      <p className="mb-0 fw-bold">
                                        {item.title}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              ))}
                              {/* {getMyTasklevel2.filter(
                                (item) => item.type === selectedOS
                              ).map((item, index) => {
                                return (
                                  <div
                                    className="col-lg-4 col-md-4 col-6 my-2"
                                    key={index}
                                  >
                                    <div
                                      className="dropdown-box"
                                      onClick={() => {
                                        let route = item.mainServiceId.title.charAt(0).toUpperCase() + item.mainServiceId.title.slice(1);
                                        localStorage.setItem(
                                          "category",
                                          item.route
                                        );
                                        localStorage.setItem(
                                          "subcategory",
                                          item.type
                                        );
                                        localStorage.setItem(
                                          "title",
                                          item.name
                                        );
                                        navigate(route);
                                        setOSContent(false);
                                      }}
                                    >
                                      <img
                                        className="drop-box-img mb-3"
                                        src={item.icon}
                                        alt={item.icon}
                                      />
                                      <div>
                                        <p className="mb-0 fw-bold">
                                          {item.name}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                );
                              })} */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </CustomModal>
    </div>
  );
}
