import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import DashboardV2 from "./Admin/Component/AdminDashboard/DashboardV2";
import History from "./Admin/Component/AdminHistory/History";

import AdminManagement from "./Admin/Component/AdminManagement/AdminManagement";
import AdminProfileDetails from "./Admin/Component/AdminManagement/AdminProfileDetails";
import AdminTaskDetails from "./Admin/Component/AdminManagement/AdminTaskDetails";
import MyTask from "./Admin/Component/AdminMytask/MyTask";
import Profile from "./Admin/Component/AdminProfile/Profile";
import Purchase from "./Admin/Component/AdminPurchase/Purchase";
import AdminRoleManagement from "./Admin/Component/AdminRoleManagement/AdminRoleManagement";
import MyServices from "./Admin/Component/AdminService/MyServices";
import EmployeeManagement from "./Admin/Component/EmployeeManagement/EmployeeManagement";
import EmployeeProfileDetails from "./Admin/Component/EmployeeManagement/EmployeeProfileDetails";
import EmployeeTaskDetails from "./Admin/Component/EmployeeManagement/EmployeeTaskDetails";
import EmployeeRoleManagement from "./Admin/Component/EmployeeRoleManagement/EmployeeRoleManagement";
import AdmingoldAuction from "./Admin/Component/GoldAuction/AdmingoldAuction";
import LogManagement from "./Admin/Component/LogManagement/LogManagement";
import ServicePackage from "./Admin/Component/ServicePackage/ServicePackage";
import ServiceRequest from "./Admin/Component/ServiceRequest/ServiceRequest";
import ServiceRequestManagement from "./Admin/Component/ServiceRequestManagement/ServiceRequestManagement";
import UserManagement from "./Admin/Component/UserManagement/UserManagement";
import UserProfileDetails from "./Admin/Component/UserManagement/UserProfileDetails";

import Adminproperty from "./Admin/Component/Auction/Adminproperty";
import Createpropertyadmin from "./Admin/Component/Auction/Createpropertyadmin";
import Creategoldauction from "./Admin/Component/GoldAuction/Creategoldauction";
import PropertygoldAuction from "./Admin/Component/GoldAuction/PropertygoldAuction";

import Propertydetail from "./Admin/Component/Auction/Propertydetail";
import VehicleList from "./Admin/Component/Wheeler/VehicleList";

import JobEdit from "./Admin/Component/JobManagement/JobEdit";
import JobManagement from "./Admin/Component/JobManagement/JobManagement";
import About from "./Pages/About";
import Apartment from "./Pages/Apartment";
import ApartmentDetails from "./Pages/ApartmentDetails";
import Auction from "./Pages/Auction";
import Audit from "./Pages/Audit";
import BSApartment from "./Pages/BSApartment";
import BSApartmentDetails from "./Pages/BSApartmentDetails";
import BSHouse from "./Pages/BSHouse";
import BSHouseDetails from "./Pages/BSHouseDetails";
import BSPlot from "./Pages/BSPlot";
import BSPlotDetails from "./Pages/BSPlotDetails";
import BSVilla from "./Pages/BSVilla";
import BSVillaDetails from "./Pages/BSVillaDetails";
import Career from "./Pages/Career";
import Contact from "./Pages/Contact";
import Home from "./Pages/Home";
import House from "./Pages/House";
import HouseDetails from "./Pages/HouseDetails";
import Legal from "./Pages/Legal";
import Marketing from "./Pages/Marketing";
import Plot from "./Pages/Plot";
import PlotDetails from "./Pages/PlotDetails";
import Services from "./Pages/Services";
import Villa from "./Pages/Villa";
import VillaDetails from "./Pages/VillaDetails";

import LeadEdit from "./Admin/Component/AdminLead/LeadEdit";
import GoldEdit from "./Admin/Component/GoldAuction/GoldEdit";
// import WheelerEdit from "./Admin/Component/Wheeler/WheelerEdit";
import WheelerEdit from "./Admin/Component/Wheeler/WheelerEdit";

import Lead from "./Admin/Component/AdminLead/Lead";
import AdminServiceDetails from "./Admin/Component/AdminManagement/AdminServiceDetail";
import MyTaskEdit from "./Admin/Component/AdminMytask/MyTaskEdit";
import TaskEdit from "./Admin/Component/AdminMytask/TaskEdit";
import TaskList from "./Admin/Component/AdminMytask/TaskList";
import NewsLetter from "./Admin/Component/AdminNewsLetter/NewsLetter";
import NewsLetterEdit from "./Admin/Component/AdminNewsLetter/NewsLetterEdit";
import Pricing from "./Admin/Component/AdminPricing/Pricing";
import PricingEdit from "./Admin/Component/AdminPricing/PricingEdit";
import PropertyAuctionDetail from "./Admin/Component/Auction/PropertyAuctionDetail";
import Content from "./Admin/Component/CMS/Content";
import ContentEdit from "./Admin/Component/CMS/ContentEdit";
import Level1 from "./Admin/Component/CMS/Level1";
import Level2 from "./Admin/Component/CMS/Level2";
import Main from "./Admin/Component/CMS/Main";
import ContactUsers from "./Admin/Component/ContactUsers/ContactUsers";
import EmployeeServiceDetails from "./Admin/Component/EmployeeManagement/EmployeeServiceDetails";
import JobApplication from "./Admin/Component/JobApplication/JobApplication";
import JobApplicationEdit from "./Admin/Component/JobApplication/JobApplicationEdit";
import LeaveEdit from "./Admin/Component/Leave/LeaveEdit";
import LeaveModule from "./Admin/Component/Leave/LeaveModule";
import PaymentPackages from "./Admin/Component/PaymentPackages/PaymentPackages";
import PaymentServices from "./Admin/Component/PaymentServices/PaymentServices";
import PropertyRequest from "./Admin/Component/PropertyRequest/PropertyRequest";
import ServiceRequestEdit from "./Admin/Component/ServiceRequest/ServiceRequestEdit";
import UserServiceDetails from "./Admin/Component/UserManagement/UserServiceDetails";
import AdmincreateWheeler from "./Admin/Component/Wheeler/AdmincreateWheeler";
import CreateVehicleAuction from "./Admin/Component/Wheeler/CreateVehicleAuction";
import VehicleSpecificView from "./Admin/Component/Wheeler/VehicleSpecificView";
import { CCAvenue } from "./Components/CCAvenue";
import BSFourWheeler from "./Pages/BSFourWheeler";
import BSGold from "./Pages/BSGold";
import BSTwoWheeler from "./Pages/BSTwoWheeler";
import FourWheeler from "./Pages/FourWheeler";
import Gold from "./Pages/Gold";
import ManPower from "./Pages/ManPower";
import OrderConfirmation from "./Pages/OrderConfirmation";
import PrivacyandPolicy from "./Pages/PrivacyandPolicy";
import Retainer from "./Pages/Retainership";
import TermsandCondition from "./Pages/TermsandCondition";
import TwoWheeler from "./Pages/TwoWheeler";
import CancellationAndRefundPolicy from "./Pages/cancellation-and-refund-policy";
import WebsiteLeads from "./Admin/Component/AdminLead/WebsiteLeads";
import GoldAuctionDetail from "./Admin/Component/Auction/GoldAuctionDetail";
import AuctionLeads from "./Admin/Component/AdminLead/AuctionLeads";
import PartnerWithUs from "./Pages/PartnerWithUs";
import Partner from "./Admin/Component/Partner/Partner";
import ScheduleMeet from "./Components/v1/ScheduleMeet/ScheduleMeet";
import FreelancerWithUs from "./Pages/FreelancerWithUs";
import Freelauncer from "./Admin/Component/Partner/Freelauncer";
import ServiceListing from "./Pages/v1/Services/ServiceListing";
import SpecificService from "./Pages/v1/Services/SpecificService";
import ScheduleMeeting from "./Pages/v1/Admin/ScheduleMeeting/ScheduleMeeting";
import VechileAuctionDetail from "./Admin/Component/Auction/VechileAuctionDetail";
import Dashboard from "./Pages/v1/PartnerFreelancer/Dashboard";
import UpdatePassword from "./Pages/v1/PartnerFreelancer/Profile/UpdatePassword";
import UpdateProfile from "./Pages/v1/PartnerFreelancer/Profile/UpdateProfile";
import ListLeads from "./Pages/v1/PartnerFreelancer/Leads/ListLeads";
import CreateLead from "./Pages/v1/PartnerFreelancer/Leads/CreateLead";
import UpdateLead from "./Pages/v1/PartnerFreelancer/Leads/UpdateLead";
import ViewLead from "./Pages/v1/PartnerFreelancer/Leads/ViewLead";
import CreateContact from "./Pages/v1/PartnerFreelancer/Leads/CreateContact";
import EditContactComponent from "./Components/v1/FreelancerPartner/Leads/EditContactComponent";
import UpdateContact from "./Pages/v1/PartnerFreelancer/Leads/UpdateContact";
import CreateNotes from "./Pages/v1/PartnerFreelancer/Leads/CreateNotes";
import CreateTask from "./Pages/v1/PartnerFreelancer/Leads/CreateTask";
import ListTask from "./Pages/v1/PartnerFreelancer/Leads/ListTask";
import ViewTask from "./Pages/v1/PartnerFreelancer/Leads/ViewTask";
import ViewSpecificTask from "./Pages/v1/PartnerFreelancer/Leads/ViewSpecificTask";
import CreateEstimate from "./Pages/v1/PartnerFreelancer/Leads/CreateEstimate";
import UpdateEstimate from "./Pages/v1/PartnerFreelancer/Leads/UpdateEstimate";
import AttendanceReport from "./Components/v1/Admin/Attendance/AttendanceReport";
import SpecificAttendanceReport from "./Components/v1/Admin/Attendance/SpecificAttendanceReport";
import PropertyUserViewAuctionDetail from "./Admin/Component/Auction/PropertyUserViewAuctionDetail";
import AuctionPurchaseHistory from "./Admin/Component/AdminLead/AuctionPurchaseHistory";
import RevenueDashboard from "./Admin/Component/AdminDashboard/RevenueDashboard";
import AttendanceDashboard from "./Admin/Component/AdminDashboard/AttendanceDashboard";
import InvestorList from "./Admin/Component/Partner/InvestorList";
import DalmafSchemes from "./Pages/DalmafSchemes";
import SchemesRequestList from "./Pages/v1/User/SchemesRequestList";
import ViewSpecificSchemes from "./Pages/v1/User/ViewSpecificSchemes";
import MyPlansList from "./Pages/v1/User/MyPlansList";
import PlanPaymentValidation from "./Pages/plan-payment-validation";
import MyBAPlansList from "./Pages/v1/User/MyBAPlansList";
import MyEcornerplansList from "./Pages/v1/User/MyEcornerplansList";


export default function RoutesList() {

  const userType = ['entrepreneurportal', 'partner'];
  const routeType = [
    {
      route: 'lead',
      label: 'Lead',
      isClient: false
    },
    {
      route: 'client',
      label: 'Client',
      isClient: true
    }
  ]
  let routeList = [];
  userType.map((user,userIndex) => {
    routeType.map((data) => {
      routeList.push(
        {
          routeId: data.route + "-01",
          userTypeProps: user,
          routeProps: data.route,
          lableProps: data.label,
          routeLink: "dashboard",
          componentName: Dashboard,
          isClientProps: data.isClient,
        },
        {
          routeId: data.route + "-01",
          userTypeProps: user,
          routeProps: data.route,
          lableProps: data.label,
          routeLink: "my-earning",
          componentName: Dashboard,
          isClientProps: data.isClient,
        },
        {
          routeId: data.route + "-02",
          userTypeProps: user,
          routeProps: data.route,
          lableProps: data.label,
          routeLink: data.route + "/my-" + data.route + "-list",
          lableProps: data.label,
          componentName: ListLeads,
          isClientProps: data.isClient,
        },
        {
          routeId: data.route + "-03",
          userTypeProps: user,
          routeProps: data.route,
          lableProps: data.label,
          routeLink: data.route + "/create-new-" + data.route + "",
          lableProps: data.label,
          componentName: CreateLead,
          isClientProps: data.isClient,
        },
        {
          routeId: data.route + "-04",
          userTypeProps: user,
          routeProps: data.route,
          lableProps: data.label,
          routeLink: data.route + "/view-" + data.route + "/:id",
          lableProps: data.label,
          componentName: ViewLead,
          isClientProps: data.isClient,
        },
        {
          routeId: data.route + "-05",
          userTypeProps: user,
          routeProps: data.route,
          lableProps: data.label,
          routeLink: data.route + "/create-contact/:id",
          lableProps: data.label,
          componentName: CreateContact,
          isClientProps: data.isClient,
        },
        {
          routeId: data.route + "-06",
          userTypeProps: user,
          routeProps: data.route,
          lableProps: data.label,
          routeLink: data.route + "/edit-contact/:id/:contactId",
          lableProps: data.label,
          componentName: UpdateContact,
          isClientProps: data.isClient,
        },
        {
          routeId: data.route + "-07",
          userTypeProps: user,
          routeProps: data.route,
          lableProps: data.label,
          routeLink: data.route + "/add-new-notes/:id",
          lableProps: data.label,
          componentName: CreateNotes,
          isClientProps: data.isClient,
        },
        {
          routeId: data.route + "-08",
          userTypeProps: user,
          routeProps: data.route,
          lableProps: data.label,
          routeLink: data.route + "/create-new-task/:id",
          lableProps: data.label,
          componentName: CreateTask,
          isClientProps: data.isClient,
        },
        {
          routeId: data.route + "-09",
          userTypeProps: user,
          routeProps: data.route,
          lableProps: data.label,
          routeLink: data.route + "/view-specific-task/:id/:taskId",
          lableProps: data.label,
          componentName: ViewSpecificTask,
          isClientProps: data.isClient,
        },
        {
          routeId: data.route + "-11",
          userTypeProps: user,
          routeProps: data.route,
          lableProps: data.label,
          routeLink: data.route + "/create-new-estimate/:id",
          lableProps: "Estimation",
          componentName: CreateEstimate,
          isClientProps: data.isClient,
        },
        {
          routeId: data.route + "-12",
          userTypeProps: user,
          routeProps: data.route,
          lableProps: data.label,
          routeLink: data.route + "/create-new-proposal/:id",
          lableProps: "Proposal",
          componentName: CreateEstimate,
          isClientProps: data.isClient,
        }
      )
    })
  })

  return (
    <div>
      <BrowserRouter>
        <Routes>

          {/* PostLogin Routes Start for Freelancer */}
          
          <Route path="entrepreneurui/task/my-task-list" element={<ListTask userTypeProps="entrepreneur" routeProps="task" lableProps="Task" isClientProps={false} />} />
          <Route path="entrepreneurui/task/view-specific-task/:taskId" element={<ViewSpecificTask userTypeProps="entrepreneur" routeProps="task" lableProps="Task" isClientProps={false} />} />
          <Route path="entrepreneurui/update-password" element={<UpdatePassword userTypeProps="entrepreneur" routeProps="task" lableProps="Task" isClientProps={false} />} />
          <Route path="entrepreneurui/update-Profile" element={<UpdateProfile userTypeProps="entrepreneur" routeProps="task" lableProps="Task" isClientProps={false} />} />


          <Route path="partner/task/my-task-list" element={<ListTask userTypeProps="partner" routeProps="task" lableProps="Task" isClientProps={true} />} />
          <Route path="partner/task/view-specific-task/:taskId" element={<ViewSpecificTask userTypeProps="partner" routeProps="task" lableProps="Task" isClientProps={true} />} />
          <Route path="partner/update-password" element={<UpdatePassword userTypeProps="partner" routeProps="task" lableProps="Task" isClientProps={true} />} />
          <Route path="partner/update-Profile" element={<UpdateProfile userTypeProps="partner" routeProps="task" lableProps="Task" isClientProps={true} />} />
          
          {
            routeList.map((data) => (
              <>
                <Route
                  key={data.routeId}
                  path={`${data.userTypeProps}/${data.routeLink}`}
                  element={<data.componentName
                    userTypeProps={data.userTypeProps}
                    routeProps={data.routeProps}
                    lableProps={data.lableProps}
                    isClientProps={data.isClientProps}
                  />}
                />
              </>

            ))
          }
          
          {/* PostLogin Routes End for Freelancer */}


          <Route path="/" element={<Home />} />
          <Route path="About" element={<About />} />
          <Route path="Legal" element={<Legal />} />
          <Route path="Career" element={<Career />} />
          <Route path="Services" element={<Services />} />
          <Route path="Contact" element={<Contact />} />
          <Route path="Auction" element={<Auction />} />
          {/* <Route path="Service" element={<Audit />} /> */}
          <Route path="ManPower" element={<ManPower />} />
          <Route path="Marketing" element={<Marketing />} />
          <Route path="PrivacyandPolicy" element={<PrivacyandPolicy />} />
          <Route path="schedule-meet" element={<ScheduleMeet />} />


          <Route path="service-listing" element={<ServiceListing />} />
          <Route path="Service" element={<SpecificService />} />


          {/* New Admin Route */}

          <Route path="Schedule-Meeting-Registration" element={<ScheduleMeeting />} />

          <Route path="admin/employee-clock-in-out" element={<AttendanceReport />} />
          <Route path="admin/employee/clock-in-out/:employeeId" element={<SpecificAttendanceReport />} />




          {/* End of New Admin Route */}



          <Route path="Privacy-Policy" element={<PrivacyandPolicy />} />
          <Route path="TermsandCondition" element={<TermsandCondition />} />
          <Route path="cancellation-and-refund-policy" element={<CancellationAndRefundPolicy />} />
          <Route path="ContentEdit/:id" element={<ContentEdit />} />
          {/* admin */}
          <Route path="Dashboard" element={<DashboardV2 />} />
          <Route path="revenue-dashboard" element={<RevenueDashboard />} />
          <Route path="attendance-dashboard" element={<AttendanceDashboard />} />
          {/* <Route path="DashboardV2" element={<DashboardV2 />} /> */}
          <Route path="LeaveModule" element={<LeaveModule />} />
          <Route path="MyServices" element={<MyServices />} />
          <Route path="Purchase" element={<Purchase />} />
          <Route path="History" element={<History />} />
          <Route path="NewsLetter" element={<NewsLetter />} />
          <Route path="NewsLetterEdit/:id" element={<NewsLetterEdit />} />
          <Route path="Profile" element={<Profile />} />
          <Route path="ServiceRequest" element={<ServiceRequest />} />
          <Route path="ServiceRequestEdit/:id" element={<ServiceRequestEdit />} />
          <Route path="PropertyRequest" element={<PropertyRequest />} />
          <Route path="MyTask" element={<MyTask />} />
          <Route path="MyTaskEdit/:id" element={<MyTaskEdit />} />
          <Route path="Vehicle/:action/:id" element={<VehicleSpecificView />} />
          <Route path="TaskList" element={<TaskList />} />
          <Route path="GoldAuction" element={<AdmingoldAuction />} />

          <Route path="OtherService&tab=Gold" element={<Gold />} />

          <Route path="OtherService&tab=TwoWheeler" element={<TwoWheeler />} />
          <Route path="OtherService&tab=FourWheeler" element={<FourWheeler />} />
          <Route path="OtherService&tab=BSTwoWheeler" element={<BSTwoWheeler />} />
          <Route path="OtherService&tab=BSFourWheeler" element={<BSFourWheeler />} />

          <Route path="OtherService&tab=BSGold" element={<BSGold />} />

          <Route path="AdmincreateWheeler" element={<AdmincreateWheeler />} />
          <Route path="CreateVehicleAuction" element={<CreateVehicleAuction />} />
          <Route path="TaskEdit/:id" element={<TaskEdit />} />
          <Route
            path="ServiceRequestManagement"
            element={<ServiceRequestManagement />}
          />
          <Route path="UserManagement" element={<UserManagement />} />
          <Route path="EmployeeManagement" element={<EmployeeManagement />} />
          <Route
            path="EmployeeRoleManagement"
            element={<EmployeeRoleManagement />}
          />
          <Route path="AdminManagement" element={<AdminManagement />} />
          <Route path="AdminRoleManagement" element={<AdminRoleManagement />} />
          <Route path="LogManagement" element={<LogManagement />} />
          <Route path="PaymentServices" element={<PaymentServices />} />
          <Route path="PaymentPackages" element={<PaymentPackages />} />
          <Route path="ContactUsers" element={<ContactUsers />} />
          <Route path="ServicePackage" element={<ServicePackage />} />
          <Route path="JobManagement" element={<JobManagement />} />
          <Route path="Main" element={<Main />} />
          <Route path="Level1" element={<Level1 />} />
          <Route path="Level2" element={<Level2 />} />
          <Route path="Content" element={<Content />} />
          <Route path="JobApplications/:id" element={<JobApplication />} />
          <Route path="Lead" element={<Lead />} />
          <Route path="website-leads" element={<WebsiteLeads />} />
          <Route path="auction-leads" element={<AuctionLeads />} />
          <Route path="auction-purchase-history" element={<AuctionPurchaseHistory />} />
          <Route path="Pricing" element={<Pricing />} />
          <Route path="JobEdit/:id" element={<JobEdit />} />
          <Route path="JobApplicationEdit/:id" element={<JobApplicationEdit />} />
          <Route
            path="LeaveEdit/:id"
            element={<LeaveEdit />}
          />
          <Route path="LeadEdit/:id" element={<LeadEdit />} />
          <Route path="GoldEdit/:id" element={<GoldEdit />} />
          <Route path="/WheelerEdit/:id" element={<WheelerEdit />} />

          <Route path="PricingEdit/:id" element={<PricingEdit />} />
          <Route path="Property" element={<Adminproperty />} />
          <Route path="Vehicle" element={<VehicleList />} />
          <Route path="OtherService&tab=Apartment" element={<Apartment />} />
          <Route
            path="OtherService&tab=BSApartment"
            element={<BSApartment />}
          />
          <Route path="OtherService&tab=Villa" element={<Villa />} />
          <Route path="OtherService&tab=BSVilla" element={<BSVilla />} />
          <Route path="OtherService&tab=IndividualHouse" element={<House />} />
          <Route
            path="OtherService&tab=BSIndividualHouse"
            element={<BSHouse />}
          />
          <Route path="OtherService&tab=Plot" element={<Plot />} />
          <Route path="OtherService&tab=BSPlot" element={<BSPlot />} />
          <Route path="ApartmentDeatails/:id" element={<ApartmentDetails />} />
          <Route
            path="BSApartmentDeatails/:id"
            element={<BSApartmentDetails />}
          />
          <Route
            path="EmployeeServiceDetails/:id"
            element={<EmployeeServiceDetails />}
          />
          <Route
            path="AdminServiceDetails/:id"
            element={<AdminServiceDetails />}
          />
          <Route path="VillaDeatails/:id" element={<VillaDetails />} />
          <Route path="BSVillaDeatails/:id" element={<BSVillaDetails />} />
          <Route path="IndividualHouse/:id" element={<HouseDetails />} />
          <Route path="BSIndividualHouse/:id" element={<BSHouseDetails />} />
          <Route path="PlotDetails/:id" element={<PlotDetails />} />
          <Route path="BSPlotDetails/:id" element={<BSPlotDetails />} />
          <Route path="Createpropertyadmin" element={<Createpropertyadmin />} />
          <Route path="Creategoldauction" element={<Creategoldauction />} />
          <Route path="GoldAuction/:action/:id" element={<PropertygoldAuction />} />
          <Route path="Property/:action/:id" element={<Propertydetail />} />
          <Route path="PropertyAuctionDetail/:id" element={<PropertyUserViewAuctionDetail />} />
          <Route path="GoldAuctionDetail/:id" element={<GoldAuctionDetail />} />
          <Route path="VechileAuctionDetail/:id" element={<VechileAuctionDetail />} />

          <Route
            path="UserProfileDetails/:id"
            element={<UserProfileDetails />}
          />
          <Route
            path="EmployeeProfileDetails/:id"
            element={<EmployeeProfileDetails />}
          />
          <Route
            path="EmployeeTaskDetails/:id"
            element={<EmployeeTaskDetails />}
          />
          <Route
            path="AdminProfileDetails/:id"
            element={<AdminProfileDetails />}
          />
          <Route path="TaskEdit/:id" element={<TaskEdit />} />

          <Route path="AdminTaskDetails/:id" element={<AdminTaskDetails />} />
          <Route path="UserServiceDetails/:id" element={<UserServiceDetails />} />
          <Route path="CCAvenue" element={<CCAvenue />} />
          <Route path="OrderConfirmation" element={<OrderConfirmation />} />
          <Route path="Retainership" element={<Retainer />} />


          <Route path="partner-with-dalmaf" element={<PartnerWithUs />} />
          <Route path="investor-list" element={<InvestorList />} />
          <Route path="partner-with-us" element={<Partner />} />
          

          <Route path="business-associate-with-dalmaf" element={<FreelancerWithUs />} />
          <Route path="become-entrepreneur" element={<Freelauncer />} />


          {/* User  */}
          <Route path="dalmaf-schemes" element={<DalmafSchemes />} />
          {/* User, Employee, Admin */}
          <Route path="schemes-Request" element={<SchemesRequestList />} />
          <Route path="scheme-details/:title/:id" element={<ViewSpecificSchemes />} />


          <Route path="my-plans" element={<MyPlansList />} />
          <Route path="my-ba-plans" element={<MyBAPlansList />} />
          <Route path="my-dpl-ecorner-plans" element={<MyEcornerplansList />} />


          <Route path="paymentvalidation/plan-payment/:reference_id" element={<PlanPaymentValidation />} />
          <Route path="paymentvalidation/plan-payment/:reference_id/:type" element={<PlanPaymentValidation />} />
          

        </Routes>
      </BrowserRouter>
    </div>
  );
}
